import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Orders from '../services/Orders';
import Helper from '../services/Helper';
import $ from 'jquery';

import Icon from './Icon.jsx';
import AuthGuard from './AuthGuard.jsx';
import Session from '../services/Session.jsx';
import { Content } from './Content.jsx';
import ContentHelper from '../services/ContentHelper.jsx';
import { APIRequest } from '../services/API.js';
import TPVWidget from './TPVWidget.jsx';
import LoadingIndicator from './LoadingIndicator.jsx';
import Events from '../services/Events.jsx';

class Pago extends Component {
    constructor(props) {
        super(props);

        this.state = {
            order_details: null,
            order_error: null,
            order_completed: false,
            order_in_progress: true,
            order_data: {},
            user_full_name: '',
            event: null,
            booking: null,
            placeholder_campo_alergias: "",
            codigo_descuento: null
        };

        ContentHelper.GetString("placeholder-campo-alergias").then(text => {
            this.setState({
                placeholder_campo_alergias: text
            });
        })

        Session.GetSession().then(session => {
            if(session && session.id) {
                this.setState({ user_full_name: session['name'] + ' ' + session['surname'] });
            }
        });
    }

    componentDidMount() {
        $('header').addClass('simple');
        let booking = Session.GetBookingData();
        let event = booking.selected_event;

        if(event && booking) {
            event = JSON.parse(event);
            Session.SetBookingData('selected_event_id', event.event_id);
            const bookingData = Session.GetBookingData();

            if (!bookingData.selected_slot) {
                window.location.href = "/evento/" + event.slug + "/" + event.id;
                return;
            }

            this.setState({
                event: event,
                booking: bookingData,
                order_error: null
            });

        } else {
            this.setState({
                order_error: null,
                order_in_progress: false
            });

            window.location.href = '/';
        }

        this.getOrderDetails();

        window.addEventListener('scroll', this.onScrollEvent);
    }

    getOrderDetails() {
        return new Promise((resolve, reject) => {
            const selected_event = JSON.parse(Session.GetBookingData('selected_event'));
			Session.SetBookingData('selected_full_slot', Helper.RenderFullTimeSlot(Session.GetBookingData('selected_slot'), selected_event.mean_lunch_time, selected_event.mean_dining_time, selected_event));

            Orders.getOrderDetails(Session.GetBookingData()).then(response => {
                if(response.status) {
                    const order_completed = response.data.order && response.data.order.payment_status === '1';
                    const codigoDescuento = response.data.order ? parseInt(response.data.order.discount_id) : null;

                    this.setState({
                        order_details: response.data,
                        order_completed,
                        event_data: response.data.event,
                        order_data: response.data.order,
                        codigo_descuento: isNaN(codigoDescuento) ? null : codigoDescuento,
                        order_in_progress: false
                    });

                    if(order_completed) {
                        window.location.href = '/reserva-completada';
                        
                    }

                    if(!sessionStorage.getItem('current_order_id') || sessionStorage.getItem('current_order_id') == 0) {
                        APIRequest('payment/create-order', Session.GetBookingData()).then(response => {
                            sessionStorage.setItem('current_order_id', response.data);
                            Orders.getOrderDetails(Session.GetBookingData()).then(response => {
                                const codigoDescuento = parseInt(response.data.order.discount_id);
                                this.setState({
                                    order_details: response.data,
                                    order_data: response.data.order,
                                    codigo_descuento: isNaN(codigoDescuento) ? null : codigoDescuento
                                });

                                resolve(response.data.order);
                            });
                        });
                    } else {
                        resolve(response.data.order);
                    }
                } else {
                    this.setState({
                        order_error: response.data.error,
                        order_in_progress: false
                    });

                    reject(response.data.error);
                }
            });
        });
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScrollEvent);
        $('header').removeClass('simple');
    }

    onScrollEvent(e) {
        if($(window).scrollTop() > 150) {
            if(!$('.booking-sidebar').hasClass('floated')) {
                $('.booking-sidebar').css({
                    position: 'fixed',
                    top: 150 + 'px',
                    left: $('.booking-sidebar').offset().left + 'px',
                    width: $('.booking-sidebar').outerWidth()
                }).addClass('floated');
            }
        } else {
            if($('.booking-sidebar').hasClass('floated')) {
                $('.booking-sidebar').css({
                    position: '',
                    top: '',
                    left: '',
                    width: ''
                }).removeClass('floated');
            }
        }
    }

    render() {
        if(!this.state.event || !this.state.booking || !this.state.order_details || !this.state.order_details.order || !sessionStorage.getItem("current_order_id")) return (
            <React.Fragment>
                <AuthGuard />
                <LoadingIndicator />
            </React.Fragment>
        );

        return (
            <div className="pago">
                <AuthGuard />
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 style={{ marginBottom: this.state.order_completed ? '1em' : 0 }}>{ this.state.order_error ? 'Parece que ha habido un problema' : 'Completa tu reserva' }</h1>
                            <h2 className={this.state.order_completed ? 'hidden' : ''}>{ this.state.order_error ? this.state.order_error : 'Te estamos guardando un sitio en ' + this.state.event.name}</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8 col-sm-12 order-container">
                            <div className="box">
                                <div className={this.state.order_error ? 'hidden' : ''}>
                                    <h3>{this.state.event.name}</h3>
                                    <h4>{this.state.order_details.event.address_name_alt ? this.state.order_details.event.address_name_alt : this.state.order_details.event.address_name}</h4>
                                    <h4>{this.state.order_details.event.address}</h4>
                                </div>
                                {this.renderOrderDetails()}
                            </div>
                            { window.innerWidth <= 991 && this.renderCajaDescuento() }
                            <div className={'box-alergia mt' + (this.state.order_error ? ' hidden' : '') }>
                                <label><Content slug="etiqueta-campo-alergias" /></label>
                                <textarea disabled={this.state.order_completed} id="notas-pedido" rows={5} placeholder={this.state.order_data.comments || this.state.placeholder_campo_alergias} defaultValue={this.state.order_data ? this.state.order_data.comments : ''}></textarea>
                            </div>

                            <TPVWidget
                                order={this.state.order_data.id}
                                amount={this.state.order_details.total}
                            />

                            {/*<label className={(this.state.order_error ? ' hidden' : '')}><input type="checkbox" disabled={this.state.order_completed} id="acepta-recibir" /> Me gustaría recibir noticias y novedades.</label>*/}
                            <button disabled={this.state.order_in_progress} className={'btn btn-brown btn-pagar' + (this.state.order_error || this.state.order_completed ? ' hidden' : '')} onClick={this.completarReserva.bind(this)}>{this.state.order_details.order.preorder_selection ? 'Pagar' : 'Pagar ticket reserva'}</button>
                            <img alt="" src="/static/images/pago.png" className="formas-pago" />
                            <div className={'disclaimer' + (this.state.order_error ? ' hidden' : '')}>Al completar tu reserva, confirmas que has leído y aceptas los <a rel="noreferrer" href="https://resaca.es/terminos-y-condiciones" target="_blank">términos de uso</a> y la <a rel="noreferrer" href="https://resaca.es/politica-de-privacidad" target="_blank">política de privacidad</a>.</div>
                        </div>
                        {this.renderBookingSidebar()}
                    </div>
                </div>
            </div>
        );
    }

    renderBookingSidebar() {
        if(this.state.order_error) return null;

        const promo_title = Session.GetBookingData("promo_name");

        return (
            <div className="col-md-4 booking-sidebar">
                <div className="box event-information">
                    <div className="event-image" style={{ backgroundImage: "url(/static/images/eventos/" + (this.state.order_details.event.image_url || this.state.order_details.event.event_image_url) + ")"}} />
                    <div className="booking-information">
                        <div className="details-line"><Icon name="calendar" />{Helper.FormatISODate(this.state.booking.selected_date).split(', ')[1]}</div>
                        <div><Icon name="clock" />{Helper.RenderFullTimeSlot(this.state.booking.selected_slot, this.state.event.mean_lunch_time, this.state.event.mean_dining_time, this.state.event)}</div>
                        <div><Icon name="users" />{this.state.booking.pax} persona{this.state.booking.pax === 1 ? '' : 's'}</div>
                        {promo_title && <div><Icon name="promo" />{promo_title}</div>}
                        <div className="change-order-link"><Link to={'/evento/' + this.state.event.slug + '/' + this.state.order_details.event.id}>¿Quieres modificar tu reserva?</Link></div>
                    </div>
                </div>

                {this.renderCajaDescuento()}
            </div>
        );
    }

    onGotOrderId(order_id) {
        if(!this.state.order_details.order) {
            this.getOrderDetails();
        } else {
            const new_order_details = {...this.state.order_details};
            new_order_details.order.id = order_id;
            this.setState(new_order_details);
        }
    }

    renderCajaDescuento() {
        return (
            <div className="box caja-descuento">
                {this.state.codigo_descuento != null && this.state.codigo_descuento !== 0 &&
                    <React.Fragment>
                        <div>¡Código descuento aplicado!</div>
                        <button className="btn btn-brown aplicar-codigo-descuento" onClick={e => {
                            APIRequest("payment/unapply-discount-code", { order_id: this.state.order_data.id }).then(response => {
                                this.setState({
                                    codigo_descuento: null,
                                    codigo_descuento_input: null,
                                    error_codigo_descuento: null
                                });
                                this.getOrderDetails();
                            });
                        }}>Eliminar descuento</button>
                    </React.Fragment>
                }
                {
                    (this.state.codigo_descuento === null || this.state.codigo_descuento === 0) &&
                    <React.Fragment>
                        <div>¿Tienes un código descuento Spotnow?</div>
                        <input className="codigo-descuento" onChange={e => {
                            this.setState({ codigo_descuento_input: e.target.value });
                        }}></input>
                        {this.state.error_codigo_descuento && <div className="error-codigo-descuento">{this.state.error_codigo_descuento}</div>}
                        <button className="btn btn-brown aplicar-codigo-descuento" onClick={e => {
                            APIRequest("payment/apply-discount-code", { code: this.state.codigo_descuento_input, order_id: this.state.order_data.id }).then(response => {
                                if(response.status) {
                                    this.setState({
                                        error_codigo_descuento: null,
                                        codigo_descuento: this.state.codigo_descuento_input
                                    });
                                    this.getOrderDetails();
                                } else {
                                    this.setState({error_codigo_descuento: "Este código no parece válido."});
                                }
                            });
                        }}>Aplicar</button>
                    </React.Fragment>
                }
            </div>
        );
    }

    completarReserva() {
        this.setState({
            order_in_progress: true
        });

        Orders.UpdateOrderPreferences(this.state.order_data.id, $("#notas-pedido").val(), $("#acepta-recibir").prop("checked")).then(() => {
            $("#tpv-form").trigger("submit");
        });
    }

    renderOrderDetails() {
        if(this.state.order_error) {
            return (
                <Link className="btn btn-brown" to="/">Volver</Link>
            );
        }
        if(!this.state.order_details) return null;

        const promo_title = Session.GetBookingData("promo_name");

        return (
            <div className="order-details">
                <div className="details-line"><Icon size="1.5" name="person" />{this.state.user_full_name}</div>
                <div className="details-line"><Icon size="1.5" name="calendar" />{Helper.FormatISODate(this.state.booking.selected_date)}</div>
                <div className="details-line"><Icon size="1.5" name="clock" />{Helper.RenderFullTimeSlot(this.state.booking.selected_slot, this.state.event.mean_lunch_time, this.state.event.mean_dining_time, this.state.event)}</div>
                <div className="details-line"><Icon size="1.5" name="users" />{this.state.booking.pax} persona{this.state.booking.pax === 1 ? '' : 's'}</div>
                {promo_title && <div className="details-line"><Icon size="1.5" name="promo" />{promo_title}</div>}
                { window.innerWidth <= 991 && <div className="change-order-link"><Link to={'/evento/' + this.state.event.slug + '?modify-booking'}>¿Quieres modificar tu reserva?</Link></div> }
                {this.state.order_details.order.preorder_selection && (
                    <div className="order-details-preorder">
                        <div className="preorder-details-line">
                            <div className="preorder-quantity">{this.state.booking.pax}</div>
                            <div className="preorder-name">{this.state.event.name}</div>
                            <div className="preorder-amount">{Helper.FormatAmount(this.state.booking.pax*this.state.order_details.event.price/100)}</div>
                        </div>
                        {this.state.order_details.order.preorder_selection.map((item, idx) => {
                            if (item.quantity <= 0) {
                                return null;
                            }

                            return (
                                <div className="preorder-details-line" key={idx}>
                                    <div className="preorder-quantity">{item.quantity}</div>
                                    <div className="preorder-name">{item.name}</div>
                                    <div className="preorder-amount">{Helper.FormatAmount(item.quantity*item.price/100)}</div>
                                </div>
                            );
                        })}
                        <div className="change-order-link"><Link to={'/evento/' + this.state.event.slug + '/extras'}>¿Quieres modificar tu selección?</Link></div>
                    </div>
                )}
                <div className="order-details-total">
                    <div>{this.state.order_details.label} (IVA incluido){!this.state.order_details.order.preorder_selection && <div className="total-label-subtitle">Se descontará en el evento</div>}</div>
                    <div>{Helper.FormatAmount(this.state.order_details.total/100)}</div>
                </div>
            </div>
        );
    }

    renderOrderType() {
        const preorderPath = Session.GetBookingData("preorder_path");
        const mealTime = Events.GetMealTimeForSlot(this.state.event, Session.GetBookingData("selected_full_slot"));

        return (
            <div className="order-type">
                <div>{preorderPath === "ticket" && <b>Reservar mesa</b>}</div>
                <div>{preorderPath === "preorder" && <b>Reservar mesa + Gastronomía</b>}</div>
                <div>{preorderPath === "takeaway" && <b>Pedir take-away</b>}</div>
                <div>{preorderPath === "delivery" && <b>Pedir delivery</b>}</div>
                {mealTime > 0 && <div>Tiempo de mesa {mealTime} min</div>}
            </div>
        );
    }
}

export default Pago;
