import React from 'react';
import { Link } from 'react-router-dom';

export default class Footer extends React.Component {
    render() {
        return (
			<footer>
				<div className="container full-width">
					<div className="row">
						<div className="col-md-6 menu-footer">
							<Link className="nav-link" to="/preguntas-frecuentes">FAQ</Link>
							<Link className="nav-link" to="/contacto">CONTACTO</Link>
							<a rel="noreferrer" href="https://instagram.com/spotnow.io" target="_blank">INSTAGRAM</a>
							<a rel="noreferrer" href="https://wa.me/34627183604" target="_blank">WHATSAPP</a>
						</div>
						<div className="col-md-6">
							
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<img alt="Resaca" className="logo" src="/static/images/spotnow_logo.png" height="24" />
						</div>
						<div className="col-md-6 right footer-links">
                            {/* <span>© 2020 SPOTNOW TECHNOLOGIES, INC.</span> */}
                            <div className="links">
								<Link to="/terminos-y-condiciones">TÉRMINOS Y CONDICIONES</Link>
                            	<Link to="/politica-de-privacidad">PRIVACIDAD</Link>
							</div>
                        </div>
					</div>
				</div>
			</footer>
        );
    }
}
