import React from 'react';
import TinySlider from 'tiny-slider-react';

export default class Carousel extends React.Component {
	constructor(props) {
		super(props);
        this.mounted = false;
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }
    
    componentDidUpdate() {
        this.onGoTo('prev');
        setTimeout(() => { this.onGoTo('next'); }, 300);
    }

    render() {
        if(window.innerWidth < 992) return (
            <div className={[ 'container', 'carousel', 'carousel-mobile', this.props.className ].join(' ')}>
                <div className="horizontal-scroll-container" style={{ width: this.props.elements.length * window.innerWidth - 60 * this.props.elements.length }}>
                    {this.props.elements.map((element, index) => this.props.renderElement(element, index))}
                </div>
            </div>
        );
        else return (
            <section className={[ 'container', 'carousel', this.props.className, this.props.elements.length <= (this.props.items || 4) ? 'hide-nav' : '' ].join(' ')}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="carousel-container">
                            <TinySlider settings={{
                                            lazyload: false,
                                            mode: 'carousel',
                                            items: this.props.items || 4,
                                            slideBy: this.props.slideBy || 1,
                                            gutter: this.props.gutter || 20,
                                            navPosition: 'bottom',
                                            navAsThumbnails: true,
                                            controls: false
                                        }} ref={ts => this.ts = ts}>
                                {this.props.elements.map((element, index) => this.props.renderElement(element, index))}
                            </TinySlider>
                        </div>
                        <div className="carousel-nav-button carousel-nav-button-left" onClick={() => this.onGoTo('prev')}><img alt="Anterior" src="/static/images/chevron-left-black.png" /></div>
                        <div className="carousel-nav-button carousel-nav-button-right" onClick={() =>  this.onGoTo('next')}><img alt="Siguiente" src="/static/images/chevron-right-black.png" /></div>
                    </div>
                </div>
            </section>
        )
    }

    onGoTo(dir) {
        //clearTimeout(this.blur_fix_timeout);
        if(this.mounted && this.ts && this.ts.slider) {
            this.ts.slider.goTo(dir);
        }
        // this.blur_fix_timeout = setTimeout(() => {
        //     document.querySelectorAll('.carousel .tns-slider').forEach(slider => this.roundCssTransformMatrix(slider) );
        // }, 300);
    }

    // roundCssTransformMatrix(element){
    //     element.style.transform=""; //resets the redifined matrix to allow recalculation, the original style should be defined in the class not inline.
    //     var mx = window.getComputedStyle(element, null); //gets the current computed style
    //     mx = mx.getPropertyValue("-webkit-transform") ||
    //         mx.getPropertyValue("-moz-transform") ||
    //         mx.getPropertyValue("-ms-transform") ||
    //         mx.getPropertyValue("-o-transform") ||
    //         mx.getPropertyValue("transform") || false;
    //     var values = mx.replace(/ |\(|\)|matrix/g,"").split(",");
    //     for(var v in values) { values[v]=Math.ceil(values[v]); }

    //     element.style.transform = "matrix("+values.join()+")";
    // }
}
