import React from 'react';

import Helper from '../services/Helper';

import Events from '../services/Events';
import Carousel from './Carousel';
import Icon from './Icon';
import LoadingIndicator from "./LoadingIndicator";

export default class CarouselEventos extends React.Component {
    constructor(props) {
        super(props);

        this.mounted = false;

        this.state = {
            elements: [],
            filtered_elements: []
        };
    }

    componentDidMount() {
        this.mounted = true;

        Events.GetEventList().then(response => {
            let filtered_elements = [];

            response.data.forEach(async element => {
                if(this.props.filters) {
                    for(let filter_index=0; filter_index<this.props.filters.length; ++filter_index) {
                        const filter = this.props.filters[filter_index];
                        if(filter.city && !Helper.StringCompare(filter.city, element.city)) return;
                        if(filter.tags && filter.tags.length && !filter.tags.filter(t => JSON.parse(element.tags).indexOf(t) !== -1).length) return;
                    }
                }
                
                if (element.nearest_available) {
                    filtered_elements.push(element);
                }
            });

            if(this.mounted) {
                this.setState({
                    elements: response.data,
                    filtered_elements
                });
            }
        });
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        if(!this.state.elements || !this.state.elements.length) return <LoadingIndicator />;

        return (
            <Carousel
                className="event-carousel"
                items={this.props.items}
                gutter={this.props.gutter}
                slideBy={this.props.slideBy}
                elements={this.state.filtered_elements}
                renderElement = {(event, index) => {
                    const bookingURL = '/evento/' + event.slug + '/' + event.nearest_available.availability_id;
                    const price = event.nearest_available.price_alt != -1 ? event.nearest_available.price_alt : event.price;

                    return (
                        <div key={index} style={{ position: 'relative' }}>
                            <div className="item-container">
                                <div className="carousel-image-container">
                                    <a className="image" href={bookingURL} style={{backgroundImage: 'url(/static/images/eventos/' + (event.image_url || event.event_image_url) + ')'}}>&nbsp;</a>
                                </div>
                                <div className="carousel-highlight-container">
                                    <div className="carousel-highlight" onClick={e => e.stopPropagation()}>{event.nearest_available.city_alt ? event.nearest_available.city_alt : event.nearest_available.city}</div>
                                    <div className="carousel-highlight carousel-pax" onClick={e => e.stopPropagation()}>{Events.RenderAvailabilityTag(event.nearest_available.pax)}</div>
                                </div>
                                <div onClick={e => {
                                    location.href = bookingURL;
                                }}>
                                    <div className="carousel-title">{event.nearest_available.name}</div>
                                    <div className="carousel-date"><Icon name="calendar" pixelSize={18} />{Helper.FormatISODate(event.nearest_available.slot.split(" ")[0])}{event.available_date_count > 1 && <div className="more-dates-icon-container" onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}><Icon name="otrasfechas" className="more-dates-icon" width={20} height={20} /><div className="icon-tooltip">Más fechas disponibles</div></div>}</div>
                                    <div className="carousel-time"><Icon name="clock" pixelSize={18} />{event.nearest_available.slot.split(" ")[1].substr(0, 5)}</div>
                                    <div className="carousel-price"><Icon name="clock" pixelSize={18} />{Helper.FormatAmount(price / 100.0)}/persona</div>
                                </div>
                            </div>
                        </div>
                    );
                }}
            />
        )
    }
}
