import React from 'react';

import { APIRequest } from '../services/API';

export default class ImagenPaso extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	componentDidMount() {
		this.mounted = true;

		if(this.props.slug) {
			APIRequest('cms/get', { slug: this.props.slug }).then(response => {
				if(this.mounted) {
					this.setState({ url: response.data.content });
				}
			});
		} else if(this.props.imageUrl) {
			if(this.mounted) {
				this.setState({ url: this.props.imageUrl });
			}
		}
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	render() {
		if(!this.state.url) return null;
		return (
			<div className={ this.props.className + ' imagen-paso ' + this.props.slug }>
				<img alt={this.props.alt} src={this.state.url} />
			</div>
		);
	}
}