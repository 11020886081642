export default class Helper {
    static FormatISODate(str, verbose) {
        if (!verbose || typeof verbose === "undefined") {
            verbose = 3;
        }
        let date = new Date();

        if(str) {
            const s = str.split('-');
            date = new Date(s[0], s[1] - 1, s[2]);
        }

        if(verbose === 1) {
            return date.toLocaleDateString('es');
        } else if(verbose === 2) {
            return date.toLocaleDateString('es', { year: 'numeric', month: 'long', day: 'numeric' });
        } else {
            return date.toLocaleDateString('es', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
        }
    }

    static CreateDateCompatible(ISODateString) {
        const components = ISODateString.split(" ")[0].split("-");
        return new Date(components[0], components[1] - 1, components[2]);
    }

    static GetISODate(date) {
        const day = (date.getDate() < 10 ? '0' : '') + date.getDate();
        const month = ((date.getMonth()+1) < 10 ? '0' : '') + (date.getMonth()+1);
        const year = date.getFullYear();
        return year + '-' + month + '-' + day;
    }

    static FormatAmount(amount, forceDecimals = false) {
        const split = ("" + amount).split(".");

        const integer = split[0];

        let fraction = 0;
        if (split.length > 1) {
            fraction = split[1];
        }

        const integerString = "" + integer;
        let formattedInteger = integerString.substr(-1);

        for (let i=1; i<integerString.length; i++) {
            if (i%3 == 0) {
                formattedInteger = integerString.substr(-(i+1), 1) + "." + formattedInteger;
            } else {
                formattedInteger = integerString.substr(-(i+1), 1) + formattedInteger;
            }
        }
        
        if (fraction > 0 || forceDecimals) {
            if (fraction < 10) {
                fraction = "0" + parseInt(fraction);
            }
            return formattedInteger + "," + fraction + " €";
        } else {
            return formattedInteger + " €";
        }
    }

    static ValidateEmail(value) {
        // eslint-disable-next-line no-control-regex
        return value.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);
    }

    static SngNormalize(str) {
        return str.toLowerCase().replace('à', 'a').replace('á', 'a').replace('è', 'e').replace('é', 'e').replace('í', 'i').replace('ò', 'o').replace('ó', 'o').replace('ú', 'u');
    }

    static StringCompare(str1, str2) {
        const str1_normalized = Helper.StringNormalize(str1);
        const str2_normalized = Helper.StringNormalize(str2);
        return str1_normalized === str2_normalized;
    }

    static RenderFullTimeSlot(startTime, meanLunchTime, meanDiningTime, event) {
        let hours = parseInt(startTime.split(':')[0]);
        let minutes = parseInt(startTime.split(':')[1]);

        let hoursStr;
        let minutesStr;

        if(minutes < 10) minutesStr = '0' + minutes;
        else minutesStr = '' + minutes;
        if(hours < 10) hoursStr = '0' + hours;
        else hoursStr = '' + hours;
        startTime = hours + ":" + minutes;

        minutes = parseInt(minutesStr);
        hours = parseInt(hoursStr);

        if (typeof event !== "undefined" && event.uses_limited_slots == 0) {
            return startTime;
        } else {
            if(hours >= 16) {
                minutes += parseInt(meanDiningTime);
            } else {
                minutes += parseInt(meanLunchTime);
            }

            while(minutes >= 60) {
                minutes -= 60;
                hours++;
            }

            if(minutes < 10) minutesStr = '0' + minutes;
            else minutesStr = '' + minutes;

            return startTime + ' a ' + hours + ':' + minutes;
        }
    }

    static RenderPriceRating(rating) {
        let output = '';
        for(let i=0; i<rating; ++i) {
            output += '€';
        }
        return output;
    }

    static RenderTags(tags) {
        if(!tags) return '';
        return tags.join ? tags.join(' · ').toUpperCase() : JSON.parse(tags).join(' · ').toUpperCase();
    }

    static GetCityShortCode(city) {
        switch(city) {
            default: return city;
            case "Madrid": return "MAD";
            case "València": return "VLC";
        }
    }

    static SummarizeText(text, maxLength) {
        if (text.length > maxLength) {
            text = text.substr(0, maxLength - 1) + "…";
        }
        return text;
    }

    static FilterObject(obj, callback) {
        const array = Object.entries(obj);
        const filtered = array.filter(([k, v]) => callback(v, k));
        return Object.fromEntries(filtered);
    }
}
