import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FormInput from './FormInput.jsx';
import Session from './../services/Session';
import $ from 'jquery';

import AuthGuard from './AuthGuard.jsx';
import Orders from '../services/Orders.jsx';
import LoadingIndicator from './LoadingIndicator.jsx';
import Helper from '../services/Helper.jsx';
import Icon from './Icon.jsx';
import TabMenu from './TabMenu.jsx';
import AsyncButton from './AsyncButton.jsx';
import Events from '../services/Events.jsx';

class MiCuenta extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error_message: null,
            profileSaveError: null,
            passwordChangeError: null,
            passwordChangedMessage: null,
            promoCodes: [],
            current_order: Session.GetBookingData()
        };

        this.changePasswordRef = null;
        this.saveProfileRef = null

        Session.GetSession().then(session => {
            this.setState({ session });
        });

        Orders.GetOrdersList().then(orders => {
            console.log(orders)
            this.setState({ orders });
        });

        Session.GetPromoCodes().then(promoCodes => {
            this.setState({ promoCodes });
        });
    }

    render() {
        if (!this.state.session || !this.state.orders) {
            return (
                <React.Fragment>
                    <AuthGuard />
                    <LoadingIndicator />
                </React.Fragment>
            );
        }

        let currentOrder = null;

        this.state.orders.forEach((order, idx) => {
            order.bookedDateObject = Helper.CreateDateCompatible(order.booked_date);
            order.bookedDateObject.setHours(order.booked_date.split(" ")[1].split(":")[0], order.booked_date.split(" ")[1].split(":")[1], order.booked_date.split(" ")[1].split(":")[2]);

            if (parseInt(order.payment_status) === 0 && order.event.parent.id === this.state.current_order.selected_event_id && order.booked_date === this.state.current_order.selected_full_date) {
                currentOrder = order;
                currentOrder.in_progress = true;
            }
        });

        return (
            <div className="mi-cuenta">
                <AuthGuard />
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>Mi cuenta</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col upper-tab-menu-container">
                            <TabMenu
                            tabs={[
                                {
                                    caption: "Usuario",
                                    render: this.renderTabUsuario.bind(this),
                                    onEnter: () => {
                                        $("#nombre").val(this.state.session.name || "");
                                        $("#apellidos").val(this.state.session.surname || "");
                                        $("#telefono").val(this.state.session.phone || "");
                                        $("#email").val(this.state.session.login || "");
                                    }
                                },
                                {
                                    caption: "Cambiar contraseña",
                                    render: this.renderTabCambiarContrasena.bind(this),
                                    onEnter: () => {
                                        $("#password, #password2").val("");
                                    }
                                },
                                {
                                    caption: "Salir",
                                    onClick: () => {
                                        Session.Logout().then(() => {
                                            window.location.href = '/';
                                        });
                                    }
                                }
                            ]}
                            />
                        </div>
                        { this.state.promoCodes.length > 0 &&
                        <div className="col-lg col-sm-12">
                            <h3>Códigos descuento</h3>
                            {this.state.promoCodes.map((promoCode, idx) => {
                                return (
                                    <div className="promo-code-row" key={idx}>
                                        <div className="promo-code-image-container" style={{ backgroundImage: "url(/static/images/promos/" + promoCode.image + ")" }} />
                                        <div>
                                            <div className="promo-code-title">{promoCode.title}</div>
                                            <div className="promo-code-description">{promoCode.description}</div>
                                            <div className="promo-code-code">Código: {promoCode.code}</div>
                                            <div className="promo-code-description">Caduca {Helper.FormatISODate(promoCode.expiration_date, 2)}</div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div> }
                        { this.state.promoCodes.length === 0 &&
                        <div className="col-lg col-sm-12 no-promos">
                            <h3>Códigos descuento</h3>
                            <div className="promo-code-row">
                                <div className="promo-code-image-container" />
                                <div>
                                    <div className="promo-code-title">No hay descuentos activos</div>
                                    <div className="promo-code-description"><span className="placeholder-bar" style={{width: "40%"}} /></div>
                                    <div className="promo-code-code"><span className="placeholder-bar" style={{width: "70%"}} /></div>
                                    <div className="promo-code-description"><span className="placeholder-bar" style={{width: "60%"}} /></div>
                                </div>
                            </div>
                        </div> }
                        { currentOrder &&
                        <div className="col-lg-3 col-sm-12">
                            <div className="current-order-widget">
                                {this.renderOrder(currentOrder, 0)}
                            </div>
                        </div> }
                        { !currentOrder && <div className="col-lg-3 col-sm-12">
                            <div className="order-item pending-order-placeholder" style={{
                                paddingBottom: 1
                            }} onClick={e => {
                                
                            }}>
                                <div className="restaurant-image" style={{backgroundColor: '#dedede77'}}></div>
                                <div className="restaurant-name"></div>
                                <div className="info-line"><Icon name="calendar" /><span className="placeholder-bar" style={{width: "20%"}} /></div>
                                <div className="info-line"><Icon name="clock" /><span className="placeholder-bar" style={{width: "40%"}} /></div>
                                <div className="info-line"><Icon name="users" /><span className="placeholder-bar" style={{width: "18%"}} /></div>
                                <Link className="btn btn-brown rounded" to="/eventos">RESERVAR AHORA</Link>
                            </div>
                        </div> }
                    </div>
                    <div className="row">
                        <div className="col">
                            <h2>Reservas</h2>
                        </div>
                    </div>
                    <TabMenu
                    className="tab-menu-reservas"
                    tabs={[
                        {
                            caption: "Próximas",
                            render: () => {
                                return this.renderOrderGrid(order => parseInt(order.payment_status) === 1 && order.bookedDateObject > new Date(), false, true);
                            }
                        },
                        {
                            caption: "Pasadas",
                            render: () => {
                                return this.renderOrderGrid(order => parseInt(order.payment_status) === 1 && order.bookedDateObject <= new Date());
                            }
                        },
                        {
                            caption: "Canceladas",
                            render: () => {
                                return this.renderOrderGrid(order => parseInt(order.payment_status) === 0 && !order.in_progress, true, false, "RESERVAR AHORA");
                            }
                        }
                    ]}
                    />
                </div>
            </div>
        );
    }

    renderTabCambiarContrasena() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <form autoComplete="off"
                        className="change-password-form"
                        onSubmit={this.onSubmitContrasena.bind(this)}>
                             <FormInput required
                                type="password"
                                id="password"
                                label="Contraseña"
                                onValidate={value => {
                                    if(value.length < 8) return 'La contraseña debe tener al menos 8 caracteres. Una contraseña fuerte debe contener letras, números y símbolos.';
                                }}
                                onChange={value => {
                                    this.setState({ password: value })
                                }}
                            />
                            <FormInput required
                                type="password"
                                id="password2"
                                label="Repetir contraseña"
                                onValidate={value => {
                                    if(value.length && this.state.password !== value) return 'Las contraseñas no coinciden.';
                                }}
                            />
                        </form>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md">
                        { this.state.profileError != null && <div className="error-message">{this.state.passwordChangeError}</div>}
                        <AsyncButton className="btn btn-brown" style={{ marginTop: 25 }} ref={ref => this.changePasswordRef = ref} onClick={this.onSubmitContrasena.bind(this)} caption="Cambiar contraseña" />
                    </div>
                </div>
            </div>
        );
    }

    renderTabUsuario() {
        return (
            <div className="row">
                <div className="col-md">
                    <div className="row">
                        <div className="col-md">
                            <form autoComplete="off"
                            onSubmit={this.onSubmitUsuario.bind(this)}>
                                <FormInput required type="text" id="nombre" label="Nombre" initialValue={this.state.session.name || ""}
                                onChange={value => {
                                    this.setState({ name: value })
                                }} />
                                <FormInput required type="text" id="apellidos" label="Apellidos" initialValue={this.state.session.surname || ""}
                                onChange={value => {
                                    this.setState({ surname: value })
                                }} />
                                <FormInput type="phone" id="telefono" label="Teléfono (opcional)" initialValue={this.state.session.phone || ""}
                                onChange={value => {
                                    this.setState({ phone: value || '' })
                                }} />
                                <FormInput type="email" id="email" label="Email" initialValue={this.state.session.login || ""}
                                    onValidate={value => {
                                        // eslint-disable-next-line no-control-regex
                                        if(!value.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/)) return 'Por favor introduce una dirección de email válida.';
                                    }}
                                    onChange={value => {
                                        this.setState({ login: value })
                                    }}
                                />
                            </form>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md">
                            { this.state.profileError != null && <div className="error-message">{this.state.profileError}</div>}
                            { this.state.passwordChangedMessage != null && <div className="info-message">{this.state.passwordChangedMessage}</div>}
                            <AsyncButton className="btn btn-brown" style={{ marginTop: 25 }} ref={ref => this.saveProfileRef = ref} onClick={this.onSubmitUsuario.bind(this)} caption="Guardar" confirm />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderOrderGrid(filter, showOrderAgainButton=true, showCallToAction=false, buttonCaption="REPETIR RESERVA") {
        const orders = this.state.orders;
        let orderInProgress = false;

        if (this.state.current_order && this.state.current_order.selected_event) {
            this.state.orders.forEach((order, idx) => {
                if (order.payment_status === 0 && order.event.id === this.state.current_order.selected_event_id && order.booked_date === this.state.current_order.selected_full_date) {
                    orderInProgress = true;
                }
            });
        }

        const filteredOrders = orders.filter(filter);

        return (
            <div className="order-grid" style={(window.innerWidth < 767 && filteredOrders.length) ? {width: filteredOrders.length * window.innerWidth * 0.8} : null}>
                { filteredOrders.map((order, idx) => {
                    return this.renderOrder(order, idx, showOrderAgainButton, buttonCaption);
                }) }
                {filteredOrders.length > 0 && <p style={{marginBottom: 25 }} />}
                {filteredOrders.length === 0 && <p style={{marginBottom: 25, gridColumnEnd: 4, gridColumnStart: 1 }}>No hay ninguna reserva.{ showCallToAction && <span>&nbsp;<Link to={orderInProgress ? "/pago" : "/eventos"}>Reservar ahora</Link>.</span> }</p>}
            </div>
        );
    }

    renderOrder(order, idx, showOrderAgainButton=true, buttonCaption="REPETIR RESERVA") {
        return (
            <div key={idx} className={["order-item", order.in_progress ? "in-progress" : null].join(" ")} style={{
                marginRight: 5,
                paddingBottom: showOrderAgainButton ? 1 : 10
            }} onClick={e => {
                if (order.in_progress) {
                    e.preventDefault();
                    window.location.href = '/pago';
                }
            }}>
                {order.in_progress && <span>Reserva pendiente</span>}
                <div className="restaurant-image" style={{backgroundImage: 'url(/static/images/eventos/' + (order.event.image_url_alt || order.event.parent.image_url) + ')'}}></div>
                <div className="restaurant-name">{order.event.name}</div>
                <div className="info-line"><Icon name="calendar" />{Helper.FormatISODate(order.booked_date.split(' ')[0])}</div>
                <div className="info-line"><Icon name="clock" />{order.booked_slot}</div>
                <div className="info-line"><Icon name="users" />{order.pax} persona{order.pax === 1 ? '' : 's'}</div>
                {(order.promo && order.promo.title) ?
                    <div className="info-line"><Icon name="promo" />{order.promo.title}</div> :
                    <div className="info-line">&nbsp;</div>
                }
                { showOrderAgainButton && !order.in_progress && <a className="btn btn-brown rounded" onClick={this.onRepeatOrder.bind(this, order)}>{buttonCaption}</a>}
                { showOrderAgainButton && order.in_progress && <Link className="btn btn-brown rounded" to="/pago">COMPLETAR RESERVA</Link>}
            </div>
        );
    }

    onRepeatOrder(order) {
        Session.SetBookingData("selected_restaurant", order.event);
        Session.SetBookingData("selected_restaurant_id", order.event.id);
        Session.SetBookingData("pax", order.pax);
        Session.SetBookingData("selected_full_date", null);
        Session.SetBookingData("promo_id", null);
        Session.SetBookingData("selected_slot", null);
        Session.SetBookingData("selected_full_slot", null);
        Session.SetBookingData("current_order_id", null);
        window.location.href = "/evento/" + order.event.slug + "?modify-booking";
    }

    onSubmitUsuario(e) {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        this.setState({ profileError: null });

        Session.SaveProfileData({
            name: this.state.name || this.state.session.name || "",
            surname: this.state.surname || this.state.session.surname || "",
            phone: this.state.phone || this.state.session.phone || "",
            login: this.state.login || this.state.session.login || ""
        }).then(result => {
            if (result.status) {
                this.setState({ passwordChangedMessage: result.data });
                this.saveProfileRef.reset();
            } else {
                this.setState({ profileError: result.data });
                this.saveProfileRef.reset(false);
            }
        });
    }

    onSubmitContrasena(e) {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        if($('.form-input.not-validated').length === 0) {
            Session.DirectPasswordChange(this.state.password).then(result => {
                this.changePasswordRef.reset();
            });
        }
    }
}

export default MiCuenta;
