import React from 'react';

export default class Icon extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hover: false
		};
	}

	render() {
		return (
			<div
				onMouseEnter={() => {
					this.setState({ hover: true });
				}}
				onMouseLeave={() => {
					this.setState({ hover: false });
				}}
				style={this.props.style || {
					width: this.props.pixelSize ? (this.props.pixelSize + 'px') : ((this.props.size || 1) + 'em'),
					height: this.props.pixelSize ? (this.props.pixelSize + 'px') : ((this.props.size || 1) + 'em'),
					marginRight: (this.props.size || 1) * 0.5 + 'em',
					cursor: this.props.onClick ? 'pointer' : 'default'
				}}
				className={'icon-svg-container ' + (this.props.className ? this.props.className : '') + (this.props.hover && this.state.hover ? ' hover' : '')}
				onClick={e => {
					e.stopPropagation();
					
					if (this.props.onClick) {
						return this.props.onClick();
					}

					this.setState({ hover: true });

				}} id={this.props.id}
			>
				{this.props.title && <div className="icon-tooltip">{this.props.title}</div>}
				<img alt={this.props.alt} style={{ width: this.props.pixelSize ? (this.props.pixelSize + 'px') : ((this.props.size || 1) + 'em'), height: this.props.pixelSize ? (this.props.pixelSize + 'px') : ((this.props.size || 1) + 'em') }} className="icon-svg" src={'/static/icons/' + this.props.name + '.' + (this.props.extension ? this.props.extension : 'svg')} />
				<img alt={this.props.alt} style={{ width: this.props.pixelSize ? (this.props.pixelSize + 'px') : ((this.props.size || 1) + 'em'), height: this.props.pixelSize ? (this.props.pixelSize + 'px') : ((this.props.size || 1) + 'em') }} className="icon-svg hover-image" src={'/static/icons/' + (this.props.hover ? this.props.hover : this.props.name) + '.' + (this.props.extension ? this.props.extension : 'svg')}/>
			</div>
		);
	}
}