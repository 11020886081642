import React from 'react';
import { APIRequest, APIRequestCached } from '../services/API';
import $ from 'jquery';

export async function GetJSON(slug) {
    const response = await APIRequest("cms/get", { slug });
    return JSON.parse(response.data.content);
}

export class Content extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            empty: true
        };

        this.content_id = (Math.random() * 10 + '').replace('.', '');
        this.element_id = 'content-placeholder-' + this.content_id;

        APIRequestCached('cms/get', { slug: this.props.slug }).then(response => {
            if(!response.data.content) {
                this.setState({ empty: true });

                if (this.props.onEmpty) {
                    this.props.onEmpty();
                }

                return;
            }

            this.setState({ empty: false });
            if(this.props.onLoad) this.props.onLoad(response.data.content);
            $('#' + this.element_id).replaceWith(response.data.content);
        });
    }

    render() {
        if (this.state.empty) {
            return null;
        }

        if (this.props.containerClassName) {
            return (
                <div className={this.props.containerClassName}>
                    <span id={this.element_id}></span>
                </div>
            );
        }

        return (
            <span id={this.element_id}></span>
        );
    }
}

export class ContentImage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            empty: true
        };

        this.content_id = (Math.random() * 10 + '').replace('.', '');
        this.element_id = 'content-' + this.content_id;

        APIRequestCached('cms/get', { slug: this.props.slug }).then(response => {
            if (response.data.content) {
                this.setState({ empty: false });

                if(this.props.onLoad) this.props.onLoad(response.data.content);
                $('#' + this.element_id).attr('src', response.data.content);
            } else {
                this.setState({ empty: true });
            }
        });
    }

    render() {
        if (this.state.empty) {
            return null;
        }

        if (this.props.containerClassName) {
            return (
                <div className={this.props.containerClassName}>
                <img alt={this.props.alt} className={this.props.className} id={this.element_id} />
                </div>
            );
        }
        
        return (
            <img alt={this.props.alt} className={this.props.className} id={this.element_id} />
        );
    }
}