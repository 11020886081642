import React from 'react';
import { Link } from 'react-router-dom';

import Icon from '../components/Icon';
import Session from '../services/Session';
import $ from 'jquery';

export default class MobileMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            is_logged_in: false
        };

        Session.CheckIsLoggedIn().then(is_logged_in => {
			this.setState({ is_logged_in });
		});
    }

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);
		this.handleScroll();
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll(e) {
		if(window.scrollY > 1) {
			document.body.classList.add('scrolled');
		} else {
			document.body.classList.remove('scrolled');
		}
	}

	render() {
		return (
            <React.Fragment>
			    <nav className="mobile-navbar">
                    <button
                        className="menu-button"
                        onClick={() => {
                            $("body").addClass("scroll-lock");
                            $('.mobile-menu-overlay, .mobile-menu').addClass('active');
                        }}
                    ><img alt="" src="/static/images/menu-icon.png" /></button>
                    <Link to="/"><img alt="" src="/static/images/spotnow_logo.png" className="logo" /></Link>
                    <Link to="/restaurantes" className="reservar-ahora">Reservar ahora</Link>
                </nav>
                <div className="mobile-menu-overlay" onClick={this.closeMenu}/>
                <div className="mobile-menu">
                    <div className="user-menu">
                        <Icon name={this.state.is_logged_in ? 'user-selected' : 'user'} size={2} />
                        {Session.RenderUserActions(this.closeMenu.bind(this))}
                    </div>

                    <Link to="/contacto" onClick={this.closeMenu}>Contacto</Link>
                    <Link to="/preguntas-frecuentes" onClick={this.closeMenu}>Preguntas frecuentes</Link>
                    <Link to="/para-empresas" onClick={this.closeMenu}>Para empresas</Link>
                </div>
            </React.Fragment>
		);
	}

    closeMenu() {
        $("body").removeClass("scroll-lock");
        $('.mobile-menu-overlay, .mobile-menu').removeClass('active');
    }
}
