import React from 'react';
import { Link } from 'react-router-dom';
import Helper from '../services/Helper';
import Events from '../services/Events';
import Session from '../services/Session';
import Icon from './Icon';
import LoadingIndicator from './LoadingIndicator';
import PreorderSelector from './PreorderSelector';
import $ from 'jquery';

export default class Preorder extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            event: null,
            booking: Session.GetBookingData(),
            selectionConfirmed: false,
            preorderSelection: []
        };
    }

    componentDidMount() {
        this.updateData();
        window.addEventListener('scroll', this.onScrollEvent);
        $("body").addClass("preorder");
        if (window.innerWidth <= 768) {
            $("header").hide();
        }
    }
    
    componentWillUnmount() {
        window.removeEventListener("scroll", this.onScrollEvent);
        $("body").removeClass("preorder");
        if (window.innerWidth <= 768) {
            $("header").show();
        }
    }

    onScrollEvent(e) {
        if($(window).scrollTop() > 0) {
            if(!$('.booking-sidebar').hasClass('floated')) {
                $('.booking-sidebar').css({
                    position: 'fixed',
                    top: 150 + 'px',
                    marginTop: 0,
                    left: $('.booking-sidebar').offset().left + 'px',
                    width: $('.booking-sidebar').outerWidth()
                }).addClass('floated');
            }
        } else {
            if($('.booking-sidebar').hasClass('floated')) {
                $('.booking-sidebar').css({
                    position: '',
                    top: '',
                    marginTop: '',
                    left: '',
                    width: ''
                }).removeClass('floated');
            }
        }
    }

    async updateData(event_id) {
        $('header').addClass('simple');

        if(typeof event_id === 'undefined') {
            const id = this.props.match.params.event_id;

            if(id === "") {
                window.location.href = "/eventos";
            } else if(Number.isInteger(id)) {
                event_id = id;
            } else {
                const event = await Events.getBySlug(id);
                event_id = event.id;
            }
        }

        Events.get(event_id, Session.GetBookingData("selected_full_date")).then(event => {
            this.setState({
                event
            });
        });
    }

    render() {
        if (!this.state.event) {
            return <LoadingIndicator />
        }

        const promo_title = Session.GetBookingData("promo_name");
        const price = this.state.event.price_alt != -1 ? this.state.event.price_alt : this.state.event.price;

        return (
            <div className="preorder container">
                <div className="row preorder-custom-header">{this.state.event.name}</div>
                <div className="row">
                    <div className="col-md-8 col-sm-12">
                        <PreorderSelector
                            event={this.state.event}
                            onConfirm={preorder_selection => {
                                Session.SetBookingData("preorder_selection", preorder_selection);
                                Session.RedirectAuthenticated("/pago");
                            }}
                            onTotalChanged={(total, preorderSelection) => {
                                this.setState({
                                    preorderSelection,
                                    formattedTotal: total
                                });
                            }}
                            selectionConfirmed={this.state.selectionConfirmed}
                        />
                    </div>
                    <div className="col-md-4 booking-sidebar">
                        <div className="box">
                            <div className="event-information">
                                <div>
                                    <div className="image" style={{
                                        backgroundImage: "url(/static/images/eventos/" + (this.state.event.image_url_alt || this.state.event.image_url) + ")"
                                    }}></div>
                                    <div className="name">{this.state.event.name}</div>
                                    <div className="address-name">{this.state.event.address_name_alt ? this.state.event.address_name_alt : this.state.event.address_name}</div>
                                </div>
                            </div>
                            <div className="booking-information">
                                <div className="details-line"><Icon name="calendar" />{Helper.FormatISODate(this.state.booking.selected_date).split(', ')[1]}</div>
                                <div><Icon name="clock" />{Helper.RenderFullTimeSlot(this.state.booking.selected_slot, this.state.event.mean_lunch_time, this.state.event.mean_dining_time, this.state.event)}</div>
                                <div><Icon name="users" />{this.state.booking.pax} persona{this.state.booking.pax === 1 ? '' : 's'}</div>
                                {promo_title && <div><Icon name="promo" />{promo_title}</div>}
                                <div><Icon name="users" />{(this.state.booking.pax * price/100).toLocaleString('es', {minimumFractionDigits: 2})} €</div>
                                <div className="change-order-link"><Link to={'/evento/' + this.state.event.slug + '/' + this.state.event.id}>¿Quieres modificar tu reserva?</Link></div>
                            </div>
                        </div>
                        <div className="boton-confirmar-container">
                            <a className="btn btn-brown boton-confirmar" onClick={e => {
                                e.preventDefault();
                                this.setState({ selectionConfirmed: true });
                            }}>{this.state.preorderSelection.length > 0 ? "Confirmar extras" : "Continuar sin extras"}{this.state.formattedTotal && <span className="button-total">{this.state.formattedTotal ? "+" + this.state.formattedTotal : ""}</span>}</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}