import { APIRequest } from "./API";

export default class ContentHelper {
    static async GetString(slug) {
        const response = await APIRequest("cms/get", {slug});

        if(!response.status || !response.data) {
            return "";
        }
        
        return response.data.content;
    }
}