import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FormInput from './FormInput.jsx';
import Helper from './../services/Helper';
import Session from './../services/Session';
import HTMLReactParser from 'html-react-parser';
import $ from 'jquery';

import { APIRequest } from '../services/API.js';
import AsyncButton from './AsyncButton.jsx';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect_clear: false,
            inProgress: false
        };

        this.buttonRef = null;

        Session.GetSession().then(session => {
            if(session && session.id) {
                sessionStorage.setItem('user_data', JSON.stringify(session));
                this.redirect();
            } else {
                this.setState({
                    redirect_clear: true
                });
            }
        });
    }

    redirect() {
        const login_redirect = sessionStorage.getItem('login_redirect');
        if(login_redirect) {
            sessionStorage.removeItem('login_redirect');
            window.location.href = login_redirect;
        } else {
            window.location.href = '/';
        }
    }

    componentDidUpdate() {
        $(".resend-activation").off("click").on("click", function(e) {
            e.preventDefault();
            $(".error-message").hide();
            APIRequest("users/resend-activation", {
                email: $("#email").val()
            }).then(response => {
                $(".error-message").show().html(response.data);
            });
        });
    }

    render() {
        if(!this.state.redirect_clear) return null;

        return (
            <div className="login">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 form-container">
                            <h1>Entra en SPOTNOW</h1>
                            <form autoComplete="off" onSubmit={this.onSubmit.bind(this)}>
                                <input type="hidden" autoComplete="off" />
                                <FormInput
                                    type="email"
                                    id="email"
                                    label="Email"
                                    onValidate={value => {
                                        if(!Helper.ValidateEmail(value)) return 'Por favor introduce una dirección de email válida.';
                                    }}
                                    onChange={value => {
                                        this.setState({ login: value });
                                    }}
                                />
                                <FormInput
                                    type="password"
                                    id="password"
                                    label="Contraseña"
                                    onChange={value => {
                                        this.setState({ password: value });
                                    }}
                                />

                                {
                                    this.state.error_message ? (
                                        <div className="error-message">{HTMLReactParser(this.state.error_message)}</div>
                                    ) : null
                                }
                                
                                <AsyncButton className="btn btn-brown" ref={ref => this.buttonRef = ref} onClick={this.onSubmit.bind(this)} caption="Entrar" />
                                <div className="center">¿Todavía no tienes una cuenta? <Link to="/registro">Regístrate</Link>.</div>
                                <div className="center"><Link to="/cambiar-contrasena">¿Has olvidado tu contraseña?</Link></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    onSubmit(e) {
        if(e && e.preventDefault) e.preventDefault();

        if(this.state.inProgress) return false;
        this.setState({ inProgress: true });

        Session.Login(this.state.login, this.state.password).then(response => {
            if(response.status) {
                sessionStorage.removeItem("last_session_check");
                sessionStorage.setItem('is_logged_in', '1');
                this.redirect();
            } else {
                this.buttonRef.reset();
                this.setState({
                    inProgress: false,
                    error_message: response.data
                });
            }
        });

        return false;
    }
}

export default Login;
