import React from 'react';
import { Content } from './Content';

export default class PopupCookies extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accepted: localStorage.getItem('cookies_accepted') === '1',
            preloaded: false
        };
    }
    render() {
        if(!this.state.preloaded) return <Content slug="popup-cookies" onLoad={() => { this.setState({ preloaded: true });  }}/>;

        return (
            <div className={'popup-cookies' + (this.state.accepted ? ' accepted' : '')}>
                <span><Content slug="popup-cookies" /></span>

                <button className="btn btn-brown" onClick={() => {
                    localStorage.setItem('cookies_accepted', '1');
                    this.setState({ accepted: true });
                }}>Entendido</button>
            </div>
        )
    }
}