import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CarouselEventos from './CarouselEventos.jsx';
import Footer from './Footer.jsx';
import ImagenPaso from './ImagenPaso';
import { GetJSON, Content, ContentImage } from './Content';
import Slider from './Slider.jsx';

export default function Home() {
    const [slidesConfig, setSlidesConfig] = useState([]);
    const [videoPlayed, setVideoPlayed] = useState(false);

    async function playVideo() {
        const $video = $(".step-video video");
        const video = $video[0];
        if (!$video.hasClass("playing")) {
            try {
                await video.play();
                $video.addClass("playing");
                setVideoPlayed(true);
            } catch (error) {
                $video.removeClass("playing");
                setVideoPlayed(false);
            }
        }
    }

    function pauseVideo() {
        const video = $(".step-video video")[0];
        video.pause();
        video.classList.remove("playing");
    }

    function onScroll(e) {
        if (!$(".step-video").length) {
            $(window).off("scroll", onScroll);
            return;
        }

        const videoTop = $(".step-video").offset().top;
        const videoBottom = videoTop + $(".step-video").height();
        const windowTop = $(window).scrollTop();
        const windowBottom = windowTop + window.innerHeight;

        if (windowBottom > videoTop && videoBottom > windowTop) {
            playVideo();
        } else {
            pauseVideo();
        }
    }

    useEffect(() => {
        GetJSON("configuracion-slides").then(json => {
            setSlidesConfig(json);
        });

        $(function($) {
            $(window).on("scroll", onScroll);
        });

        return () => {
            $(window).off("scroll", onScroll);
        }
    }, []);
    
    return (
        <div className="home">
            <Slider
                style={{
                    opacity: slidesConfig.length ? 1 : 0,
                }}
                effect="fade"
                delay={5000}
                slides={slidesConfig}
            />

            <section className="top-list bg-offwhite">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2><Content slug="top-list-titulo" /></h2>
                        </div>
                    </div>
                </div>
                <CarouselEventos />
                {/* <div className="container">
                    <div className="row review-quote">
                        <div className="col-md-12">
                            <Content slug="home-texto-cita" containerClassName="review-quote-text" />
                            <span className="divider" />
                            <ContentImage slug="home-imagen-cita" containerClassName="review-quote-logo" />
                        </div>
                    </div>
                </div> */}
            </section>
            <section className="steps">
                <div className="container full-width">
                    <div className="row step bg-offgrey">
                        <ImagenPaso className="col-md-6 col-sm-12" slug="home-imagen-paso-1" />
                        <div className="col-md-6 col-sm-12">
                            <div className="steps-inner">
                                <Content slug="home-paso-1-titulo" containerClassName="section-subtitle" />
                                <h2 className="section-title"><Content slug="home-subtitulo-paso-1" /></h2>
                                <Content slug="home-texto-paso-1" containerClassName="p" />
                                <br/>
                                <div className="row">
                                    <div className="col-4"><ContentImage slug="home-paso-1-logo-1" /></div>
                                    <div className="col-4"><ContentImage slug="home-paso-1-logo-2" /></div>
                                    <div className="col-4"><ContentImage slug="home-paso-1-logo-3" /></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row step bg-offgrey step-video">
                        <div className="steps-inner">
                            <video autoPlay={false} controls={false} loop={true} poster="/static/images/home/poster-frame.jpg" playsInline={true}>
                                <source src="/static/images/home/home.mp4" type="video/mp4" />
                                <source src="/static/images/home/home.webm" type="video/webm" />
                            </video>
                            {!videoPlayed && <div className="play-button" onClick={e => {
                                e.preventDefault();
                                playVideo();
                            }} />}
                        </div>
                    </div>

                    <div className="row step bg-offwhite">
                        <div className="col-md-6 col-sm-12">
                            <div className="steps-inner">
                                <Content slug="home-paso-2-titulo" containerClassName="section-subtitle" />
                                <h2 className="section-title"><Content slug="home-titulo-paso-2" /></h2>
                                <Content slug="home-subtitulo-paso-2" containerClassName="p" />
                                <Content slug="home-texto-paso-2" containerClassName="p" />
                                <br/>
                                <div className="row step-3-logos-row">
                                    <div className="col-4"><ContentImage slug="home-paso-2-logo-1" containerClassName="steps-3-logo" /></div>
                                    <div className="col-4"><ContentImage slug="home-paso-2-logo-2" containerClassName="steps-3-logo" /></div>
                                    <div className="col-4"><ContentImage slug="home-paso-2-logo-3" containerClassName="steps-3-logo" /></div>
                                </div>
                                <div className="row step-3-logos-row">
                                    <div className="col-4"><ContentImage slug="home-paso-2-logo-4" containerClassName="steps-3-logo" /></div>
                                    <div className="col-4"><ContentImage slug="home-paso-2-logo-5" containerClassName="steps-3-logo" /></div>
                                    <div className="col-4"><ContentImage slug="home-paso-2-logo-6" containerClassName="steps-3-logo" /></div>
                                </div>
                            </div>
                        </div>
                        <ImagenPaso className="col-md-6 col-sm-12" slug="home-imagen-paso-2" />
                    </div>
                </div>
            </section>
            <section className="bottom-banner bg-offwhite">
                <div className="container full-width">
                    <div className="row">
                        <div className="col-md-6">
                            <ContentImage className="cake-image" slug="home-imagen-tarta" />
                        </div>
                        <div className="col-md-6">
                            <div className="caption-text">
                                <h2><Content slug="home-ventajas-titulo" /></h2>
                                <h3><Content slug="home-ventajas-subtitulo" /></h3>
                                <Link to="/contacto" className="btn btn-brown">CONTACTO</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

