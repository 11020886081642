import React from 'react';
import $ from 'jquery';

export default class FormInput extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {
			is_valid: true,
			validated: this.props.required ? false : true,
			empty: true,
			validation_message: ''
		};

		this.initialValueSet = false;
	}

	componentDidMount() {
		if (!this.initialValueSet) {
			this.initialValueSet = true;
			$("#" + this.props.id).val(this.props.initialValue || "");
		}
	}

	render() {
		return (
			<div className={(this.state.validated ? 'validated ' : 'not-validated ' ) + 'form-input ' + this.props.id + '-container ' + this.props.className} style={this.props.style}>
				{ this.props.type === 'textarea' ?
				<textarea
					autocomplete="one-time-code"
					className={[ this.state.empty ? '' : 'non-empty', this.state.is_valid ? '' : 'non-valid' ].join(' ')} type={this.props.type}

					onChange={e => {
						this.setState({
							empty: e.target.value === ''
						});

						if(this.props.onChange) { this.setState({ state: e.target.value }); this.props.onChange(e.target.value); }
						this.forceUpdate();
					}}

					onBlur={e => {
						if(this.props.required && e.target.value.trim() === '') {
							this.setState({
								validation_message: 'Este campo es obligatorio.',
								is_valid: false,
								validated: false
							});
						} else if(this.props.onValidate) {
							const validation_result = this.props.onValidate(e.target.value);
							this.setState({
								validation_message: validation_result,
								is_valid: !validation_result,
								validated: !validation_result
							});
						} else {
							this.setState({
								validation_message: '',
								is_valid: true,
								validated: true
							});
						}
					}}

                    style={this.props.formStyle}

					id={this.props.id}
					name={this.props.id}
				/>
				:
				<input
					autoComplete={this.props.type === 'password' ? 'new-password' : 'one-time-code'}
					className={[ this.state.empty ? '' : 'non-empty', this.state.is_valid ? '' : 'non-valid' ].join(' ')} type={this.props.type}

					onChange={e => {
						this.setState({
							empty: e.target.value === ''
						});

						if(this.props.onChange) { this.setState({ state: e.target.value }); this.props.onChange(e.target.value); }
						this.forceUpdate();
					}}

					onBlur={e => {
						if(this.props.required && e.target.value.trim() === '') {
							this.setState({
								validation_message: 'Este campo es obligatorio.',
								is_valid: false,
								validated: false
							});
						} else if(this.props.onValidate) {
							const validation_result = this.props.onValidate(e.target.value);
							this.setState({
								validation_message: validation_result,
								is_valid: !validation_result,
								validated: !validation_result
							});
						} else {
							this.setState({
								validation_message: '',
								is_valid: true,
								validated: true
							});
						}
					}}

                    style={this.props.formStyle}

					id={this.props.id}
					name={this.props.id}
				/>
				}
				<label htmlFor={this.props.id}>{this.props.label}</label>
				<div className="validation-message">{this.state.validation_message}</div>
			</div>
		);
	}
}
