import { APIAdminRequest } from "./API";

export default class AdminService {
    static async IsUserAdmin() {
        const response = await APIAdminRequest("is-admin");
        return response.status;
    }

    static async GetContent(searchQuery = "") {
        const response = await APIAdminRequest("get-content", { searchQuery });
        return response.data;
    }

    static async SaveContent(slug, content, type) {
        const response = await APIAdminRequest("save-content", { slug, content, type });
        return response.status;
    }

    static async Login(user, password) {
        const response = await APIAdminRequest("admin-auth", { user, password });
        return response.status;
    }

    static async Logout() {
        const response = await APIAdminRequest("admin-logout");
        return response.status;
    }
}