import React, { Component } from 'react';
import Footer from './Footer.jsx';
import FormInput from './FormInput.jsx';

import Forms from '../services/Forms.jsx';
import AsyncButton from './AsyncButton.jsx';
import $ from 'jquery';
import { Content } from './Content.jsx';

class FormularioContacto extends Component {
    constructor(props) {
        super(props);

		this.state = {
			errorMessage: ""
		};
	}
	
	componentDidMount() {
		$('body').addClass('bg-offwhite');
	}

	componentWillUnmount() {
		$('body').removeClass('bg-offwhite');
	}

    render() {
        return (
            <div className="formulario-contacto bg-offwhite">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
							<Content slug="contacto-titulo" containerClassName="contacto-titulo" />
							<Content slug="contacto-subtitulo" containerClassName="contacto-subtitulo" />
							
							<h2>Datos</h2>
							<FormInput onChange={value => this.setState({ nombre: value })} required label="Nombre*" id="nombre" />
							<FormInput onChange={value => this.setState({ email: value })} required type="email" label="Email*" id="email"
								onValidate={value => {
									// eslint-disable-next-line no-control-regex
									if(!value.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/)) return 'Por favor introduce una dirección de email válida.';
								}}
							/>
							<FormInput onChange={value => this.setState({ telefono: value })} required label="Teléfono*" id="telefono" />
							<FormInput onChange={value => this.setState({ nombre_empresa: value })} label="Nombre de la empresa (si es corporativo)" id="nombre-empresa" />

							<h2>Comentarios*</h2>
							<FormInput onChange={value => this.setState({ comentarios: value })} required label="Gastronomía, precios, tiempo de mesa, etc." id="comentarios" type="textarea" />
                        </div>
                    </div>

					<div className="error-message">{this.state.errorMessage}</div>

					<AsyncButton className="boton-enviar-contacto" onClick={button => {

						this.setState({ errorMessage: "" });

						if(
							!this.state.nombre ||
							!this.state.email ||
							!this.state.telefono ||
							!this.state.comentarios
						) {
							button.reset();
							this.setState({ errorMessage: "Por favor rellena todos los campos obligatorios." });
							return;
						}

						Forms.sendForm('preguntas', {
							nombre: this.state.nombre,
							email: this.state.email,
							telefono: this.state.telefono,
							nombre_empresa: this.state.nombre_empresa,
							comentarios: this.state.comentarios,
						}).then(() => {
							window.location.href = "/gracias-por-contactar-con-nosotros";
						});
					}} caption="Enviar" />
                </div>
                <Footer />
            </div>
        );
    }
}

export default FormularioContacto;