import React from 'react';
import { Link } from 'react-router-dom';
import Session from '../services/Session';
import $ from 'jquery';

import Icon from './Icon';

export default class MainMenu extends React.Component {

	constructor() {
		super();
		this.state = {
			show_user_button: true,
			is_logged_in: false
		};
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);
		this.handleScroll();

		Session.CheckIsLoggedIn().then(is_logged_in => {
			this.setState({ is_logged_in });
		});

		$(function($) {
			$(window).on('click', function(e) {
				const on_header = $(e.target).closest('header.desktop').length && !$(e.target).closest('header.desktop li:not(.user-account-button-container').length;
				if(!on_header) {
					$('header.desktop .navbar-container').removeClass('active');
				}
			});

			$('.user-account-button-close').on('click', function(e) {
				$('header.desktop .navbar-container').removeClass('active');
			});
		});
		
		if(this.props.onRef) {
			this.props.onRef(this);
		}
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll(e) {
		if(window.scrollY > 1) {
			document.body.classList.add('scrolled');
		} else {
			document.body.classList.remove('scrolled');
		}
	}

	render() {
		return (
			<nav className="navbar navbar-expand-md">
				<div className="container full-width mw-95 py-1 py-lg-0">
					<div className="d-none d-lg-block">
						<Link className="navbar-brand" to="/"><img alt="Resaca" className="logo" src="/static/images/spotnow_logo.png" height="24px" /></Link>
					</div>
					<div className="navbar-collapse collapse" id="navbarResponsive">
						<ul className="navbar-nav mr-auto">
							{/* <li className="nav-item">
								<Link className="nav-link" to="/restaurantes">Reservar mesa</Link>
							</li> */}
							{/* <li className="nav-item">
								<Link className="nav-link" to="/planear-evento">Planear evento</Link>
							</li> */}
						</ul>
						<div className="navbar-container">
							<ul className="navbar-nav">
								<li>
									<Link className="nav-link nav-btn" to="/para-empresas">Para empresas</Link>
								</li>
								<li className="user-account-button-container"><Icon
									name={this.state.is_logged_in ? 'user-selected' : 'user'}
									size="2"
									className="user-account-button"
									onMouseEnter={() => {
									}}
									onMouseLeave={() => {
										
									}}
									onClick={() => {
										this.setState({ show_user_menu: true });
										$('header.desktop .navbar-container').toggleClass('active');
									}} 
								/></li>
							</ul>
							<ul className="navbar-nav user-actions">
								{Session.RenderUserActions(null, true)}
								<li className="user-account-button-close">
									<div className="tock-icon"></div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</nav>
		);
	}
}
