import React from 'react';

import Events from '../services/Events';
import Calendar from '../components/Calendar';
import Helper from '../services/Helper';
import Orders from '../services/Orders';
import Session from '../services/Session';
import AsyncButton from './AsyncButton';
import Icon from './Icon';
import { Content } from './Content';
import $ from 'jquery';

export default class BookingWidget extends React.Component {
	constructor(props) {
		super(props);

		this.previous_promos_fetch_booking_data = {
			selected_date: '',
			selected_slot: ''
		};

		this.calendar_ref = null;
		this.confirmButtonRef = null;

		this.state = {
			availability: [],
			available_slots: [],
			preorder_path: "",
			slots_error: null,
			loading_slots: false,
			selected_date: Session.GetBookingData('selected_date'),
			eligible_promos: [],
			selected_promo: 0,
			slot_confirmed: false,
			preorder_confirmed: false,
		};
	}

	componentDidMount() {
		this.onPaxChanged();

		const selected_date = Session.GetBookingData('selected_date');
		if(this.state.selected_date !== selected_date) {
			this.setState({ selected_date });
			this.onDateChanged();
		}

		if(this.props.onRef) {
			this.props.onRef(this);
		}

		$("body").on("click", this.onClickAnywhere.bind(this));

		this.onSelectedPreorderPath("preorder");
	}

	componentWillUnmount() {
		$("body").off("click", this.onClickAnywhere.bind(this));
	}

	componentDidUpdate() {
		if(Session.GetBookingData('selected_date')) {
			$('.calendar .day').removeClass('active');
			$('.calendar .date-' + Session.GetBookingData('selected_date')).addClass('active');
		}
	}

	returnToSelectedDate() {
        setTimeout(() => {
            let todaySelector = '.date-' + Session.GetBookingData('selected_date');

            if($(todaySelector).hasClass("no-availability")) {
                todaySelector = ".day.future:not(.no-availability)";
            }

            if(Session.GetBookingData('selected_date')) {
                this.findFirstAvailableMonth(todaySelector);
            }
        }, 1);
    }

	findFirstAvailableMonth(todaySelector) {
		if($(todaySelector).first().is(":visible")) {
			$(todaySelector).first().trigger('click');
		} else {
			if ($('.calendar-next').length) {
				$('.calendar-next').trigger('click');
				setTimeout(() => {
					this.findFirstAvailableMonth(todaySelector);
				}, 1);
			}
		}
	}

	onClickAnywhere() {
		this.setState({
			priceTooltipActive: false
		});
	}

	hasPreorderOptions() {
		return this.props.event.accepts_preorder > 0 ||
		this.props.event.accepts_takeaway > 0 ||
		this.props.event.accepts_delivery > 0;
	}

	onSelectedPreorderPath(selection) {
		if (selection !== Session.GetBookingData("preorder_path")) {
			Session.SetBookingData("preorder_selection", null);
		}
		Session.SetBookingData("preorder_path", selection);
		this.setState({ preorder_path: selection });

		if (this.props.modalRef) {
			this.props.modalRef.setState({
				onBack: null,
				custom_title: null
			});
		}
		//this.returnToSelectedDate();
	}

	render() {
		const horizontal_layout = this.props.layout === 'horizontal';

		if (horizontal_layout && this.hasPreorderOptions() && this.state.preorder_path === "") {
			return (
				<PreorderPathSelector event={this.props.event} onConfirm={selection => {
					this.onSelectedPreorderPath(selection);
				}} />
			);
		}

		if(horizontal_layout && this.state.slot_confirmed) {
			if(this.state.eligible_promos.length) {
				return (
					<PromosSelector promos={this.state.eligible_promos} onConfirm={promo_id => {
						Session.SetBookingData('promo_id', promo_id);
						Session.SetBookingData('promo_name', this.state.eligible_promos.find(promo => promo.id === promo_id).title);

						this.props.onConfirm()
					}} />
				);
			}
		}

		const pax = Session.GetBookingData('pax');
		const selected_date = Session.GetBookingData('selected_date');

		return (
			<div className={'booking-widget ' + (this.props.className || '') + (horizontal_layout ? ' horizontal' : '') }>
				<div className="selectors">
					<div className="pax-selector">
						<span className={"pax" + (this.props.event.pax == 0 ? " disabled" : "")}>{this.paxHtml()}</span>
						<span onClick={() => { if(pax <= 1 || this.props.event.pax == 0) return; Session.SetBookingData('pax', pax - 1); this.onPaxChanged(); }} className={'pax-button' + (pax === 1 || this.props.event.pax == 0 ? ' disabled' : '')}><i className="tock-icon icon_minus"></i></span>
						<span onClick={() => { if (pax >= this.props.event.pax) return; Session.SetBookingData('pax', pax + 1); this.onPaxChanged(); }} className={"pax-button" + (this.props.event.pax == 0 ? " disabled" : "")}><i className="tock-icon icon_plus"></i></span>
					</div>
					{ horizontal_layout ? <div className="selected-date"><span>{Helper.FormatISODate(selected_date, 1)}</span></div> : null }
					{ this.state.preorder_path !== "preorder" && horizontal_layout ? (<div className="total-price-label"><div>{window.innerWidth > 991 && <span>Ticket reserva:&nbsp;</span>}{Helper.FormatAmount(pax * this.props.event.price/100) }<div className="icono-info"><i className="fa fa-info-circle"></i><div className="icon-tooltip">El ticket de reserva es un medio de confirmación de asistencia con el restaurante que se descontará del total de la cuenta.</div><div className="price-label-subtitle">Se descontará en el restaurante</div></div></div></div>) : null }
				</div>
				{ horizontal_layout ? this.renderCalendar() : null }
				{ horizontal_layout ? this.renderSlots() : <AsyncButton disabled={this.props.disabled} onClick={e => {
					Session.SetBookingData('promo_id', null);
					Session.SetBookingData('promo_name', null);
					this.props.onConfirm();
					this.confirmButtonRef.reset();
				}} onRef={ref => this.confirmButtonRef = ref} className="btn btn-brown" caption={this.props.event.pax > 0 ? "RESERVAR | " + Helper.FormatAmount(pax * this.paxPrice()/100) : "COMPLETO"} /> }
			</div>
		);
	}

	renderSlots() {
		const pax = Session.GetBookingData('pax');
		const selected_date = Session.GetBookingData('selected_date');

		if(this.state.slots_error) return ( <div className="slots-error">No se ha podido conectar con el servidor:<br/>{this.state.slots_error}</div> );

		return (
			<React.Fragment>
				{this.state.available_slots.length === 0 && <div className="slots-error">No hay plazas libres para la fecha seleccionada.</div>}
				{this.state.available_slots.length > 0 && (
				<React.Fragment><h3 className="slot-buttons-heading">Hora</h3>
				<div className={'slot-buttons' + (selected_date ? '' : ' disabled')}>
					{this.state.available_slots.map((slot, i) => {

						const availabilityRow = this.state.availability.rows.find(r => r.slot.substr(11, 5) == slot.time);

						return (
							<div key={i} data-value={slot.time} onClick={() => {
								if (!selected_date) return;
								Session.SetBookingData('selected_slot', slot.time);
								this.onSlotSelected();
							}}>{slot.label}{JSON.stringify(availabilityRow)}<span>{Helper.FormatAmount(pax * this.paxPrice()/100)}</span></div>
						)
					})}
				</div></React.Fragment>) }
			</React.Fragment>
		);
	}

	onSlotSelected() {
		this.setState({ slot_confirmed: true });

		if(this.state.eligible_promos.length) {
			this.props.modalRef.setState({ onBack: () => {
				if (this.state.preorder_path !== "preorder") {
					this.setState({ slot_confirmed: false });

					this.props.modalRef.setState({
						onBack: null,
						custom_title: null
					});

					setTimeout(() => {
						if(Session.GetBookingData('selected_date')) {
							const daySelector = '.date-' + Session.GetBookingData('selected_date');
							this.findFirstAvailableMonth(daySelector);
						}
					}, 1);
				} else {
					this.setState({ slot_confirmed: false });

					this.props.modalRef.setState({
						onBack: null,
						custom_title: null
					});
				}
			}});

			this.props.modalRef.setTitle('Selecciona tu descuento');

		} else {
			this.props.onConfirm();
		}
	}

	totalPrice() {
		return this.paxPrice() * (Session.GetBookingData('pax') || 1);
	}

	paxPrice() {
		return this.props.event.price_alt != -1 ? this.props.event.price_alt : this.props.event.price;
	}

	renderCalendar() {
		return <Calendar
			onRef={ref => this.calendar_ref = ref}
			onChanged={date => {
				Session.SetBookingData('selected_date', date);
				this.setState({ selected_date: date });
				this.onDateChanged();
			}}
			onPageChanged={() => {
				this.fadeUnavailableDays();
			}}
		/>;
	}

	onDateChanged(e) {
		if(e) {
			const selected_date = e.target.value;
			Session.SetBookingData('selected_date', selected_date);
		}
		this.onPaxChanged().then(() => {
			let total_day_availability = 0;
			if(this.state.availability.calendar[this.state.selected_date]) Object.keys(this.state.availability.calendar[this.state.selected_date]).forEach(time => {
				if(this.state.availability.calendar[Session.GetBookingData('selected_date')]) {
					total_day_availability += parseInt(this.state.availability.calendar[Session.GetBookingData('selected_date')][time]);
				}
			});

			this.setState({
				selected_date_available: this.state.availability.calendar && total_day_availability > 0
			});
		});
	}

	paxHtml() {
		if (this.props.event.pax > 0 && Session.GetBookingData("pax") > this.props.event.pax) {
			Session.SetBookingData("pax", this.props.event.pax);
		}
		return Session.GetBookingData('pax') + ' persona' + (Session.GetBookingData('pax') === 1 ? '' : 's');
	}

	async fetchPromos() {
		if(this.previous_promos_fetch_booking_data.pax !== Session.GetBookingData('pax') || this.previous_promos_fetch_booking_data.selected_date !== Session.GetBookingData('selected_date') || this.previous_promos_fetch_booking_data.selected_slot !== Session.GetBookingData('selected_slot')) {
			this.previous_promos_fetch_booking_data = Session.GetBookingData();
			const eligible_promos = await Orders.GetEligiblePromos();
			this.setState({
				eligible_promos
			});

			return eligible_promos
		}

		return [];
	}

	async updateAvailability() {
		$(".slot-buttons").css("opacity", 0.5);
		this.setState({ loading_slots: true });
		const availability = await Events.GetAvailability(this.props.event.slug, Session.GetBookingData('pax'));

		const mean_lunch_time = parseInt(this.props.event.mean_lunch_time);
		const mean_dining_time = parseInt(this.props.event.mean_dining_time);
		const available_times = availability.calendar[this.state.selected_date];

		let available_slots = [];
		if(available_times) Object.keys(available_times).forEach(time => {
			const available_pax = available_times[time];
			if(available_pax <= 0) return;

			let d = new Date();
			const hour = parseInt(time.split(':')[0]);
			let minutes = parseInt(time.split(':')[1]);

			if(
				d.getFullYear() ===  parseInt(this.state.selected_date.split('-')[0]) &&
				(d.getMonth() + 1) ===  parseInt(this.state.selected_date.split('-')[1]) &&
				d.getDate() ===  parseInt(this.state.selected_date.split('-')[2])
			) {
				if(hour < d.getHours() + 12) return;
			}

			available_slots.push({
				time,
				label: Helper.RenderFullTimeSlot(hour + ":" + minutes, mean_lunch_time, mean_dining_time, this.props.event)
			});
		});

		this.fadeUnavailableDays(availability.calendar);

		if(Session.GetBookingData('selected_event')) {
			this.fetchPromos();
		}

		$(".slot-buttons").css("opacity", 1);

		this.setState({
			available_slots,
			slots_error: null,
			availability: availability,
			loading_slots: false
		});
	}

	fadeUnavailableDays(availability) {
		$('.calendar .day').addClass('no-availability');
		Object.keys(availability || this.state.availability.calendar || []).forEach(date => {
			$('.calendar .day.date-' + date).removeClass('no-availability');
		});
	}

    async onPaxChanged() {
		await this.updateAvailability();
    }
}

class PromosSelector extends React.Component {
	render() {
		return (
			<div className="listado-promos">
				{this.props.promos.map((promo, idx) => {
					return (
						<div className="promos-row" key={idx} onClick={e => {
							e.preventDefault();
							this.props.onConfirm(promo.id);
						}}>
							<div className="promo-image" style={{backgroundImage: 'url(/static/images/promos/' + promo.image + ')'}}></div>
							<div className="promo-info">
								<div className="promo-title">{promo.title}</div>
								<div className="promo-descripcion">{promo.description}</div>
							</div>
							<div className="promo-button" onClick={e => {
								e.preventDefault();
								this.props.onConfirm(promo.id);
							}}>
								<a href="#" className="boton-seleccionar-promo">Seleccionar</a>
							</div>
						</div>
					);
				})}
			</div>
		);
	}
}

class PreorderPathSelector extends React.Component {
	render() {
		return (
			<div className="preorder-path-selector">
				{ this.props.event.accepts_booking > 0 && <div className="preorder-path-option">
					<Icon name="table" pixelSize={125} hover={false} />
					<h3 className="mobile-only">MESA</h3>
					<div className="option-details">
						<h3 className="desktop-only">MESA</h3>
						<div><Content slug="texto-reserva-mesa" /></div>
						<a className="btn btn-brown" onClick={e => {
							this.props.onConfirm("ticket");
						}}>RESERVAR MESA</a>
					</div>
				</div>
				}
				{ this.props.event.accepts_preorder > 0 && <div className="preorder-path-option">
					<Icon name="table-food" pixelSize={125} hover={false} />
					<h3 className="mobile-only">MESA<br/>+ GASTRONOMÍA</h3>
					<div className="option-details">
						<h3 className="desktop-only">MESA + GASTRONOMÍA</h3>
						<div><Content slug="texto-reserva-gastronomia" /></div>
						<a className="btn btn-brown" href="#" onClick={e => {
							e.preventDefault();
							this.props.onConfirm("preorder");
						}}>RESERVAR MESA + GASTRONOMÍA</a>
					</div>
				</div>
				}
				{ this.props.event.accepts_takeaway > 0 && <div className="preorder-path-option">
					<Icon name="takeaway" pixelSize={125} hover={false} />
					<h3 className="mobile-only">TAKE AWAY</h3>
					<div className="option-details">
						<h3 className="desktop-only">TAKE AWAY</h3>
						<div><Content slug="texto-reserva-take-away" /></div>
						<a className="btn btn-brown disabled" href="#" onClick={e => {
							e.preventDefault();
						}}>PRÓXIMAMENTE</a>
					</div>
				</div>
				}
				{ this.props.event.accepts_delivery > 0 && <div className="preorder-path-option">
					<Icon name="delivery" pixelSize={125} hover={false} />
					<h3 className="mobile-only">DELIVERY</h3>
					<div className="option-details">
						<h3 className="desktop-only">DELIVERY</h3>
						<div><Content slug="texto-reserva-delivery" /></div>
						<a className="btn btn-brown disabled" onClick={e => {
							e.preventDefault();
						}}>PRÓXIMAMENTE</a>
					</div>
				</div>
				}
			</div>
		);
	}
}

