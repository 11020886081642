import React from "react";
import Helper from "../services/Helper";
import $ from 'jquery';

import Modal from "./Modal";
import Session from "../services/Session";

export default class PreorderSelector extends React.Component {
	constructor(props) {
		super(props);

        const quantities = {};
        const preorder_selection = Session.GetBookingData("preorder_selection");
        if (preorder_selection) {
            preorder_selection.forEach(item => {
                quantities[item.name] = {
                    name: item.name,
                    price: item.price,
                    quantity: item.quantity
                }
            });
        }

        this.state = {
            modal_data: {
                dish: null,
                quantity: 1
            },
            quantities
        };

        this.itemModalRef = null;
	}

    recalculateWidths() {
        $(".categories h2").first().addClass("active");
        $(".injected-css").remove();
        $("body").append("<style class='injected-css'>body:not(.scrolled) header { background-color: white; }</style>");

        if (window.innerWidth > 767) {
            let categoriesWidth = 0;
            $(".categories h2").each(function() {
                categoriesWidth += $(this).width();
            });

            if (categoriesWidth < $(".categories").parent().width()) {
                $(".categories").css("display", "flex");
            }

            $($(".categories-container").css({
                left: $(".categories-container").parent().offset().left,
                right: window.innerWidth - ($(".categories-container").parent().offset().left + $(".categories-container").parent().width()) - 15
            }));
        }

        // @ts-ignore
        $(window).off("scroll").on("scroll", function(e) {
            $(".categories h2").each(function() {
                const index = $(this).attr("data-category-index");
                if ($(".items h2[data-category-index=" + index + "]").offset().top <= $(this).offset().top + 80) {
                    $(".categories h2").removeClass("active");
                    $(this).addClass("active");
                    
                    onActiveCategoryChanged();
                }
            });
        });

        // @ts-ignore
        $(".categories h2").off("click").on("click", function(e) {
            const index = $(this).attr("data-category-index");
            const $this = $(this);

            $("body, html").animate({
                scrollTop: $(".items h2[data-category-index=" + index + "]").offset().top - $("header").height() - $(".categories-container").height() - 24
            }, "fast", function() {
                $(".categories h2").removeClass("active");
                $this.addClass("active");
                
                onActiveCategoryChanged();
            });
        });

        let animationInProgress = false;
        let animationDelta = 0;
        let previousScrollLeft = $(".categories-container").scrollLeft();

        function onActiveCategoryChanged() {
            if (!$(".categories h2.active").length) {
                return;
            }

            let innerCategoriesWidth = 0;
            $(".categories-container .categories h2").each(function() {
                $(this).attr("data-offset", innerCategoriesWidth);
                innerCategoriesWidth += $(this).outerWidth();
            });
            
            function animateScrollFrame() {
                const $category = $(".categories h2.active");
                const target = Math.max(0, Math.min(innerCategoriesWidth - $(".categories-container").outerWidth(), $category.attr("data-offset")));

                previousScrollLeft = $(".categories-container").scrollLeft();
                $(".categories-container").scrollLeft($(".categories-container").scrollLeft() + (target - $(".categories-container").scrollLeft()) / 10);
                animationDelta = Math.abs(previousScrollLeft = $(".categories-container").scrollLeft() - previousScrollLeft);

                if (animationDelta <= 1.0 || Math.floor(target) === Math.floor($(".categories-container").scrollLeft())) {
                    animationInProgress = false;
                } else {
                    requestAnimationFrame(animateScrollFrame);
                }
            }
            
            if (!animationInProgress) {
                animationInProgress = true;
                requestAnimationFrame(animateScrollFrame);
            }
        }
    }

    componentDidMount() {
        this.calculateTotal();

        $(($) => {
            window.addEventListener("resize", this.recalculateWidths);
            this.recalculateWidths();
        });
    }

    componentWillUnmount() {
        $(".injected-css").remove();
        $("header").removeClass("simple");
        window.removeEventListener("resize", this.recalculateWidths);
    }

    componentDidUpdate() {
        if (this.props.selectionConfirmed) {
            this.confirmSelection();
        }
    }

    getFormattedTotal() {
        let total = 0;
        Object.keys(this.state.quantities).forEach(dishName => {
            total += this.state.quantities[dishName].quantity * this.state.quantities[dishName].price;
        });

        return Helper.FormatAmount(total/100);
    }

    calculateTotal() {
        let total = 0;
        
        const quantities = this.state.quantities;

        Object.keys(quantities).forEach(dishName => {
            total += quantities[dishName].quantity * quantities[dishName].price;
        });

        this.props.onTotalChanged(total > 0 ? this.getFormattedTotal(): "", Object.values(this.state.quantities).filter(v => v.quantity > 0));
    }

    confirmSelection() {
        this.props.onConfirm(Object.values(this.state.quantities));
    }

	render() {
		const menu = JSON.parse(this.props.event.preorder_menu);
		const categories = Object.keys(menu);

		return (
            <React.Fragment>
                <div className="preorder-selector categorized-scroller">
                    <div className="categories-container">
                        <div className="categories">
                            {categories.map((category, idx) => {
                                return (
                                    <h2 key={idx} data-category-index={idx}>{category}</h2>
                                )
                            })}
                        </div>
                    </div>
                    <div className="items">
                        {categories.map((category, idx) => {
                            return (
                                <div className="item-category" key={idx}>
                                    <h2 data-category-index={idx}>{category}</h2>
                                    {menu[category].description && <h3 className="category-description">{menu[category].description}</h3>}
                                    <div className="items-grid">
                                        {menu[category].items.map((dish, idx) => {
                                            return (
                                                <div className="item" key={idx} onClick={e => {
                                                    e.preventDefault();
                                                    this.setState({
                                                        modal_data: {...this.state.modal_data, dish, quantity: this.state.quantities[dish.name] ? Math.max(1, this.state.quantities[dish.name].quantity) : 1}
                                                    });

                                                    this.itemModalRef.open();
                                                    this.itemModalRef.setTitle(dish.name);
                                                }}>
                                                    <div>
                                                        <h3>{dish.name}</h3>
                                                        <div className="item-description">{Helper.SummarizeText(dish.description, (window.innerWidth > 767 ? 72 : 60))}</div>
                                                        <div className="item-price">{Helper.FormatAmount(dish.price/100.0, true)}</div>
                                                    </div>
                                                    <div className="item-image" style={{backgroundImage: "url(" + dish.image_url + ")"}}><img alt="" src={dish.image_url} /></div>
                                                    {this.state.quantities[dish.name] && this.state.quantities[dish.name].quantity > 0 && <div className="quantity">{this.state.quantities[dish.name].quantity}</div>}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="boton-confirmar-container">
                        <a className="btn btn-brown boton-confirmar" onClick={e => {
                            this.confirmSelection();
                        }}>
                            {Object.keys(this.state.quantities).length > 0 && <span>Confirmar extras <span style={{ marginLeft: 25 }}>+{this.getFormattedTotal()}</span></span>}
                            {Object.keys(this.state.quantities).length == 0 && <span>Continuar sin extras <span className="button-total"></span></span>}
                        </a>
                    </div>
                </div>

                <Modal ref={ref => this.itemModalRef = ref} renderContent={() => {
                    return (
                        <div className="item">
                            <div>
                                <div className="item-image" style={{backgroundImage: "url(" + this.state.modal_data.dish.image_url + ")"}}><img src={this.state.modal_data.dish.image_url} alt="" /></div>
                                <div className="item-description">{this.state.modal_data.dish.description}</div>
                                <div className="item-quantity-selector">
                                    <div className="qty-controls">
                                        <div className="qty-button" onClick={e => {
                                            e.preventDefault();
                                            this.setState({
                                                modal_data: {...this.state.modal_data, quantity: Math.max(0, this.state.modal_data.quantity - 1)}
                                            });
                                        }}><i className="tock-icon icon_minus"></i></div>
                                        <div className="qty" data-price={this.state.modal_data.dish.price} data-dish-name={this.state.modal_data.dish.name}>{this.state.modal_data.quantity}</div>
                                        <div className="qty-button" onClick={e => {
                                            e.preventDefault();
                                            this.setState({
                                                modal_data: {...this.state.modal_data, quantity: this.state.modal_data.quantity + 1}
                                            });
                                        }}><i className="tock-icon icon_plus"></i></div>
                                    </div>
                                    <a className="btn btn-brown confirm-item" onClick={e => {
                                        e.preventDefault();
                                        const quantities = this.state.quantities;
                                        
                                        if (!quantities[this.state.modal_data.dish.name]) {
                                            quantities[this.state.modal_data.dish.name] = {
                                                name: this.state.modal_data.dish.name,
                                                quantity: 0,
                                                price: this.state.modal_data.dish.price
                                            }
                                        }
                                        quantities[this.state.modal_data.dish.name].quantity = this.state.modal_data.quantity;

                                        this.setState({
                                            quantities: Helper.FilterObject(quantities, (v, k) => {
                                                return v.quantity > 0;
                                            })
                                        });

                                        this.itemModalRef.close();
                                    }}>
                                        {(!this.state.quantities[this.state.modal_data.dish.name] || this.state.modal_data.quantity > this.state.quantities[this.state.modal_data.dish.name].quantity) && <div>Añadir <span className="item-price">{Helper.FormatAmount((this.state.modal_data.quantity * this.state.modal_data.dish.price)/100.0, true)}</span></div>}
                                        {(this.state.quantities[this.state.modal_data.dish.name] && this.state.modal_data.quantity <= this.state.quantities[this.state.modal_data.dish.name].quantity) && <div>Actualizar {this.state.modal_data.quantity > 0 && <span className="item-price">{Helper.FormatAmount((this.state.modal_data.quantity * this.state.modal_data.dish.price)/100.0, true)}</span>}</div>}
                                    </a>
                                </div>
                            </div>
                        </div>
                    );
                }}
                onClose={() => {
                    this.calculateTotal();
                }}
                />
            </React.Fragment>
		)
	}
}