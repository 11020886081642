import React from 'react';
import Ciudades from '../services/Ciudades';
import Helper from '../services/Helper';
import Events from '../services/Events';
import Calendar from './Calendar';
import LoadingIndicator from './LoadingIndicator';
import Modal from './Modal';
import $ from 'jquery';

export default class SearchWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            parameters: {
                city: props.city || '',
                pax: -1,
                date: '',
                period: '',
                price_rating: -1
            },
            cities: [],
            citiesLoading: true,
            availability: {},
            results: []
        };

        this.savedParameters = this.state.parameters;
        this.main_modal_ref = null;
        this.city_modal_ref = null;

        this.updateAvailability();

        Ciudades.GetCiudadesList().then(cities => {
            this.setState({ cities, citiesLoading: false });
        });
    }

    componentDidMount() {
        if(this.props.onRef) {
            this.props.onRef(this);
        }
    }

    componentDidUpdate() {
        this.enableCalendarDays();
    }

    enableCalendarDays(goToSelectedDate = false) {
        $('.calendar .day').addClass('no-availability');
		Object.keys(this.state.availability).forEach(event => {
		    Object.keys(this.state.availability[event]).forEach(date => {
                $('.calendar .day.date-' + date).removeClass('no-availability');
            });
        });

        if(this.state.parameters.date) {
            setTimeout(() => {
                const todaySelector = '.calendar .date-' + this.state.parameters.date;
                $(".calendar .day").removeClass("active");

                if($(todaySelector).is(":visible")) {
                    $(todaySelector).addClass("active");
                } else if (goToSelectedDate) {
                    $('.calendar-next').trigger('click');
                    setTimeout(() => {
                        if($(todaySelector).is(":visible")) {
                            $(todaySelector).addClass("active");
                        } else {
                            $('.calendar-next').trigger('click');
                            setTimeout(() => {
                                $(todaySelector).addClass("active");
                            }, 1);
                        }
                    }, 1);
                }
            }, 1);
        }
    }

    onSearchFinished(results) {
        this.setState({
            results
        });
        this.updateAvailability();
    }

    onParameterChange(parameter, value) {
        return new Promise((resolve, reject) => {

            let parameters = this.state.parameters;

            if(parameter === "pax") value = Math.max(1, value);

            parameters[parameter] = value;

            this.setState({
                parameters
            });

            if (parameter !== "date") {
                $(".calendar .day").removeClass("active");
                parameters["date"] = "";
            }

            this.parametersDidChange();

            resolve();
        });
    }

    parametersDidChange(new_parameters = null) {
        if(new_parameters) {
            this.setState({
                parameters: new_parameters
            });
        }
        
        this.updateAvailability();

        if(this.props.onChange) {
            this.props.onChange(this.state.parameters);
        }
    }

    async updateAvailability() {
        const availability = await Events.GetAvailabilityMultiple(this.state.results.map(event => event.slug), Math.max(this.state.parameters.pax, 1));

        let events = [];

        Object.keys(availability.calendar).forEach(event => {
            Object.keys(availability.calendar[event]).forEach(date => {
                let new_times = {};
                Object.keys(availability.calendar[event][date]).forEach(time => {
                    if(this.state.parameters.period && this.state.parameters.period === "comida") {
                        if(time <= Events.PeriodCutoffHour() + ":00") {
                            new_times[time] = availability.calendar[event][date][time];

                            if(!events[event]) {
                                events[event] = {};
                            }
                            events[event][date] = true;
                        }
                    } else if(this.state.parameters.period && this.state.parameters.period === "cena") {
                        if(time > Events.PeriodCutoffHour() + ":00") {
                            new_times[time] = availability.calendar[event][date][time];

                            if(!events[event]) {
                                events[event] = {};
                            }
                            events[event][date] = true;
                        }
                    } else {
                        new_times[time] = availability.calendar[event][date][time];

                        if(!events[event]) {
                            events[event] = {};
                        }
                        events[event][date] = true;
                    }
                });

                if(Object.keys(new_times).length) {
                    availability.calendar[event][date] = new_times;
                } else {
                    delete availability.calendar[event][date];
                }
            });
        });

        if(this.props.onEventListUpdated) {
            this.props.onEventListUpdated(events);
        }

        this.setState({ availability: availability.calendar });
    }

    render() {
        return (
            <React.Fragment>
                <div className="event-search">
                    {/* <Dropdown onChange={value => {
                        this.onParameterChange('city', value);
                    }}
                    defaultOption={{
                        caption: "Cualquier ciudad",
                        value: ""
                    }}
                    options={this.state.cities.map(city => {
                        return {
                            value: city.name,
                            caption: city.name
                        }
                    })}
                    /> */}
                    <div className="search-selector"
                    onClick={e => {
                        this.city_modal_ref.open();
                    }}>{this.state.parameters.city || this.props.city || "Ciudades"}</div>
                    
                    {window.innerWidth < 768 && <div className="search-separator" />}
                    
                    <div className="search-selector date-selector-container"
                    onClick={e => {
                        if($(e.target).hasClass('clear-date-button')) return;
                        this.savedParameters = JSON.parse(JSON.stringify(this.state.parameters));
                        this.main_modal_ref.open();
                    }}>
                        {this.state.parameters.date ? Helper.FormatISODate(this.state.parameters.date, 1) : <span style={{marginRight:"0.5em"}}>{window.innerWidth >= 768 ? "dd/mm/aaaa" : "Más filtros"}</span>}
                        {this.state.parameters.date && <div className="clear-date-button tock-icon" onClick={() => {
                            this.onParameterChange("date", "");
                        }}></div>}
                    </div>

                    {window.innerWidth >= 768 && this.renderPriceRatingSelector()}
                    {window.innerWidth >= 768 && this.renderPaxSelector()}

                </div>
                <Modal
                title="Selecciona una ciudad"
                className="search-city-modal"
                ref={(ref => this.city_modal_ref = ref)}
                renderContent={() => {
                    if (this.state.citiesLoading) {
                        return <LoadingIndicator />
                    }
                    return (
                        <React.Fragment>
                            {this.state.cities.map((city, idx) => {
                                return (
                                    <div
                                    onClick={e => {
                                        if(!city.active) return;
                                        this.onParameterChange("city", city.title);
                                        this.city_modal_ref.close();
                                    }}
                                    className={["city-button", !city.active ? "proximamente" : ""].join(" ")} key={idx} style={{
                                        backgroundImage: 'url(' + Ciudades.GetCityImageUrl(city.title) + ')'
                                    }}><div className="city-name-tag">{city.title}</div></div>
                                )
                            })}
                        </React.Fragment>
                    )
                }}
                onClose={() => {
                }}
                />
                <Modal
                title="Selecciona una fecha"
                className="search-calendar-modal"
                ref={(ref => this.main_modal_ref = ref)}
                renderContent={() => {
                    return (
                        <React.Fragment>
                            <div className="period-button-container">
                                <a onClick={e => {
                                    e.preventDefault();
                                    this.onParameterChange("period", "comida")
                                    $('.period-button-container a').removeClass('active');
                                    $(e.target).addClass('active');

                                    this.updateAvailability();
                                }}
                                href="#">Comida</a>

                                <a onClick={e => {
                                    e.preventDefault();
                                    this.onParameterChange("period", "cena")
                                    $('.period-button-container a').removeClass('active');
                                    $(e.target).addClass('active');

                                    this.updateAvailability();
                                }}
                                href="#">Cena</a>

                                <a className="active" onClick={e => {
                                    e.preventDefault();
                                    this.onParameterChange("period", "")
                                    $('.period-button-container a').removeClass('active');
                                    $(e.target).addClass('active');

                                    this.updateAvailability();
                                }}
                                href="#">Todas</a>
                            </div>
                            {window.innerWidth < 768 && <div className="price-and-pax-selectors">
                                {this.renderPaxSelector()}
                                {this.renderPriceRatingSelector()}
                            </div>}
                            <Calendar
                            onChanged={date => {
                                this.onParameterChange("date", date);
                                this.main_modal_ref.close();
                                this.forceUpdate();
                            }}
                            onPageChanged={() => {
                                this.enableCalendarDays();
                            }}
                            />
                        </React.Fragment>
                    )
                }}
                onOpen={() => {
                    this.enableCalendarDays(true);
                }}
                onClose={() => {
                    
                }}
                onCancel={() => {
                    this.parametersDidChange(this.savedParameters);
                }}/>
            </React.Fragment>
        )
    }

    renderPriceRatingSelector() {
        return (
            <div className="search-selector price-rating-container">
                <div className="price-rating">
                    <a className={this.state.parameters.price_rating === 1 ? 'active' : ''} onClick={async e => {
                        e.preventDefault();
                        if(this.state.parameters.price_rating === 1) {
                            this.onParameterChange('price_rating', -1);
                        } else {
                            this.onParameterChange('price_rating', 1);
                        }
                    }} href="#">€</a>
                    <a className={this.state.parameters.price_rating === 2 ? 'active' : ''} onClick={async e => {
                        e.preventDefault();
                        if(this.state.parameters.price_rating === 2) {
                            this.onParameterChange('price_rating', -1);
                        } else {
                            this.onParameterChange('price_rating', 2);
                        }
                    }} href="#">€€</a>
                    <a className={this.state.parameters.price_rating === 3 ? 'active' : ''} onClick={async e => {
                        e.preventDefault();
                        if(this.state.parameters.price_rating === 3) {
                            this.onParameterChange('price_rating', -1);
                        } else {
                            this.onParameterChange('price_rating', 3);
                        }
                    }} href="#">€€€</a>
                </div>
            </div>
        );
    }

    renderPaxSelector() {
        return (
            <div className="search-selector pax-selector-container">
                <div className="pax-selector">
                    <span>{Math.max(1, this.state.parameters.pax || 1)} persona{Math.max(1, this.state.parameters.pax || 1) !== 1 ? 's' : ''}</span>
                    <a href="#" onClick={e => {
                            e.preventDefault();
                            this.onParameterChange("pax", (this.state.parameters.pax || 1) - 1);
                    }}><span className="tock-icon icon_minus"></span></a>
                    <a href="#" onClick={e => {
                        e.preventDefault();
                        this.onParameterChange("pax", Math.max(1, (this.state.parameters.pax || 1)) + 1);
                    }}><span className="tock-icon icon_plus"></span></a>
                </div>
            </div>
        );
    }
}