/*eslint-disable no-undef*/
const cache_enabled = true;

export function APIAdminRequest(func, data) {
    return APIRequest(func, data, API_URL + "/admin-api.php");
}

export function APIRequest(func, data, url = API_URL) {
    return new Promise((resolve, reject) => {
        if (typeof data === "undefined") data = null;

        const request = new Request(url + "?action=" + func, {
            credentials: "include",
        });
        const form_data = new FormData();

        if (data)
            Object.keys(data).forEach(function(k) {
                form_data.append(k, data[k]);
            });

        fetch(request, {
            method: "POST",
            mode: "cors",
            cache: "default",
            body: form_data,
        })
            .then((response) => {
                try {
                    const json_promise = response.json();
                    return json_promise;
                } catch (e) {
                    resolve(response);
                }
            })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function APIRequestCached(func, data, max_time = 86400000) {
    return new Promise((resolve, reject) => {
        let request_cache = JSON.parse(localStorage.getItem("request_cache") || "{}");
        const cache_key = btoa(func + JSON.stringify(data));
        const cached_content = request_cache[cache_key];
        if (cached_content && cache_enabled && Date.now() < cached_content.expires) {
            resolve(cached_content);
        } else {
            APIRequest(func, data)
                .then((response) => {
                    let request_cache = JSON.parse(localStorage.getItem("request_cache") || "{}");
                    request_cache[cache_key] = response;
                    request_cache[cache_key].expires = Date.now() + max_time; // 24 hours
                    localStorage.setItem("request_cache", JSON.stringify(request_cache));
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        }
    });
}
