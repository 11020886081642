import React from "react";
import Helper from "../services/Helper";
import Session from "../services/Session";
import { Content } from "./Content";
import Icon from "./Icon";
import LoadingIndicator from "./LoadingIndicator";

export default class GridRestaurantes extends React.Component {
    constructor(props) {
        super(props);
        this.mounted = false;

        this.state = {
            search_in_progress: false,
            results: []
        };
    }

    componentDidMount() {
        this.mounted = true;
        if(this.props.onRef) {
            this.props.onRef(this);
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    onSearchStarted() {
        if(!this.mounted) return;
        this.setState({ search_in_progress: true });
    }

    onSearchFinished(results) {
        if(!this.mounted) return;
        this.setState({
            results,
            search_in_progress: false
        });
    }

    render() {
        const visible_restaurants = this.state.results.filter(restaurant => {
            if(this.props.allowedRestaurants && this.props.allowedRestaurants !== null) {
                if(!Object.keys(this.props.allowedRestaurants).find(allowed => restaurant.slug === allowed)) {
                    return false;
                }

                if(this.props.searchParameters.date && !this.props.allowedRestaurants[restaurant.slug][this.props.searchParameters.date]) {
                    return false;
                }
            }

            return true;
        });

        return (
            <div className="grid-restaurantes container">
                {this.state.search_in_progress && <LoadingIndicator />}
                {!this.state.search_in_progress && visible_restaurants.length === 0 && (
                    <div className="no-results-error"><Content slug="buscador-no-hay-resultados" /></div>
                )}
                {visible_restaurants.map((restaurant, idx)=> {
                    const meal_time = restaurant.mean_lunch_time > 0 ? restaurant.mean_lunch_time : restaurant.mean_dinner_time;
                    return (
                        <div className="grid-item-container" key={idx} onClick={() => {
                            if(this.props.searchParameters && (this.props.searchParameters.date !== "" || this.props.searchParameters.pax !== -1)) {
                                Session.SetBookingData("selected_date", this.props.searchParameters.date);
                                Session.SetBookingData("pax", Math.max(1, this.props.searchParameters.pax));
                                window.location.href = '/evento/' + restaurant.slug + '?modify-booking';
                            } else {
                                window.location.href = '/evento/' + restaurant.slug;
                            }
                        }}>
                            {restaurant.highlight && <div className="grid-highlight">{restaurant.highlight}</div>}
                            <div className="grid-image" style={{ backgroundImage: 'url(/static/images/restaurantes/' + restaurant.image_url + ')' }}>
                            </div>
                            <div className="grid-item-info">
                                <div className="title">{restaurant.name}</div>
                                {meal_time && <div className="grid-highlight" onClick={e => {
                                    e.stopPropagation();
                                }}><div className="icon-tooltip">Tiempo de uso</div><Icon name="time" size={2.7} style={{marginRight: 0}} /> {meal_time} min</div>}
                                <div className="city">{restaurant.city} &middot; {Helper.RenderPriceRating(restaurant.price_rating)}</div>
                                <div className="tags">{Helper.RenderTags(restaurant.tags)}</div>
                            </div>
                        </div>
                    )
                })}
            </div>
        );
    }
}