import React, { Component } from 'react';
import CarouselEventos from './CarouselEventos.jsx';
import GridRestaurantes from './GridRestaurantes.jsx';
import Footer from './Footer.jsx';
import $ from 'jquery';

import { Content } from './Content.jsx';
import SearchWidget from './SearchWidget.jsx';
import Events from '../services/Events.jsx';
import Ciudades from '../services/Ciudades.jsx';
import LoadingIndicator from './LoadingIndicator.jsx';

export default class Eventos extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search_parameters: { enabled: false },
            events: null,
            searchInProgress: false,
            featured_tags: []
        };

        this.searchResultsRef = null;
        this.searchWidgetRef = null;

        Events.GetFeaturedTags().then(featured_tags => {
            this.setState({ featured_tags });
        })
    }

    componentDidMount() {
        $('header').addClass('bg-offwhite');
    }

    componentWillUnmount() {
        $('header').removeClass('bg-offwhite');
    }

    onSearchParametersChanged(search_parameters) {
        search_parameters.enabled = search_parameters.price_rating !== -1 ||
                                    search_parameters.city.trim() !== "" ||
                                    search_parameters.pax !== -1 ||
                                    search_parameters.date.trim() !== "";

        this.setState({
            search_parameters,
            searchInProgress: true
        });

        if(this.searchResultsRef) {
            this.searchResultsRef.onSearchStarted();
        }

        Events.search(search_parameters).then(results => {
            this.setState({
                searchInProgress: false
            });

            if(this.searchResultsRef) {
                this.searchResultsRef.onSearchFinished(results);
            }

            if(this.searchWidgetRef) {
                this.searchWidgetRef.onSearchFinished(results);
            }
        });
    }

    render() {
        const city_match = window.location.href.match(/\/eventos\/(.+)\/?/);
        if(city_match && city_match[1]) {
            Ciudades.GetCiudadNameFromImageName(city_match[1]).then(cityName => {
                this.onSearchParametersChanged({
                    pax: -1,
                    date: "",
                    period: "",
                    price_rating: -1,
                    city: cityName
                });
            });
            window.history.replaceState('eventos', 'Events', '/eventos');
            return null;
        }

        return (
            <div className="eventos">
                <section className="eventos-banner bg-offwhite">
                    <h1 className="reservar-lema"><Content slug="reservar-lema" /></h1>
                    <div className="reservar-subtitle"><Content slug="reservar-subtitulo" /></div>

                    <div className="container search">
                        <SearchWidget city={this.state.search_parameters.city} onRef={ref => this.searchWidgetRef = ref} onEventListUpdated={events => {
                            this.setState({events});
                        }} onChange={parameters => this.onSearchParametersChanged(parameters)} />
                    </div>
                </section>

                {this.state.searchInProgress &&
                    <div style={{ textAlign: "center" }}>
                        <LoadingIndicator />
                    </div>
                }
                {!this.state.searchInProgress && !this.state.search_parameters.enabled && this.renderDefaultEvents()}
                {!this.state.searchInProgress && this.state.search_parameters.enabled && this.renderSearchEvents()}

                <Footer />
            </div>
        );
    }

    renderDefaultEvents() {
        return (
            <React.Fragment>
                <section className="top-list">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2><Content slug="top-list-titulo" /></h2>
                                <h3><Content slug="top-list-subtitulo" /></h3>
                            </div>
                        </div>
                    </div>
                    <CarouselEventos compact={true} items={window.innerWidth >= 992 ? 5 : 1} />
                </section>
                {
                    this.state.featured_tags.map((tag, idx) => {
                        return (
                            <section className="restaurant-category" key={idx}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h2>{tag.title}</h2>
                                            <h3>{tag.subtitle}</h3>
                                        </div>
                                    </div>
                                </div>
                                <CarouselEventos compact={true} items={window.innerWidth >= 992 ? 5 : 1} filters={[{ tags: [ tag.tag ] } ]} />
                            </section>
                        );
                    })
                }
            </React.Fragment>
        );
    }

    renderSearchEvents() {
        return (
            <React.Fragment>
                <section className="top-list">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>RESULTADOS DE BÚSQUEDA</h2>
                            </div>
                        </div>
                    </div>
                    <GridRestaurantes onRef={ref => this.searchResultsRef = ref} allowedEvents={this.state.events} searchParameters={this.state.search_parameters} />
                </section>
            </React.Fragment>
        );
    }
}
