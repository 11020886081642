import React from 'react';

import Calendarize from '../../js/calendarize.js';
import $ from 'jquery';

export default class Calendar extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {
			selected_date: new Date(),
			starting_date: new Date(new Date().getFullYear(), new Date().getMonth(), 1)
		};
	}

	componentDidMount() {
		this.redrawCalendar(this.state.starting_date);
		this.changeMonth(0);

		if(this.props.onRef) {
			this.props.onRef(this);
		}
	}

	redrawCalendar(starting_date) {
		$('#calendar').html('');
		Calendarize().buildMonthsInRange(document.getElementById('calendar'), 
		{
			showDaysOfWeek: true,
			showMonth: true,
			showYear: true
		}, starting_date, 32);
		$('.day:not(.disabled, .past, .future-60)').on('click', e => {
			this.setState({ selected_date: new Date(e.currentTarget.dataset.date) });
			$('.day').removeClass('active');
			$(e.currentTarget).addClass('active');
			if(this.props.onChanged) this.props.onChanged(this.formatDate(this.state.selected_date));
		});

		if(this.props.onPageChanged) {
			this.props.onPageChanged();
		}
	}

	changeMonth(offset) {
		const new_starting_date = new Date(this.state.starting_date.getFullYear(), this.state.starting_date.getMonth() + offset, 1);
		this.setState({
			starting_date: new_starting_date
		});
		this.redrawCalendar(new_starting_date);

		setTimeout(() => {
			let max_rows = 0;
			$('.month').each(function() {
				if(!$(this).is(":visible")) return;
				
				max_rows = Math.ceil(Math.max(max_rows, ($(this).find('.day').length + $(this).find('.dummy-day:not(.day)').length)/7));
			});
			$('.month').css('height', max_rows * 40 + 88 + 'px');
		}, 1);
	}

	formatDate(date) {
		if(!date) return '';
		let day = date.getDate();
		let month = date.getMonth() + 1;
		let year = date.getFullYear();
		if(day < 10) day = '0' + day;
		if(month < 10) month = '0' + month;
		return year + '-' + month + '-' + day;
	}

	render() {
		return (
			<div className="calendar-container">
				{ this.state.starting_date.getTime() > new Date().getTime() && <div className="calendar-arrow calendar-previous" onClick={this.changeMonth.bind(this, -1)}><img alt="" src="/static/icons/desplegable-preguntas.png" /></div> }
				{ this.state.starting_date.getTime() < new Date().getTime() + (1000 * 60 * 60 * 24 * 30 * 6) && <div className="calendar-arrow calendar-next" onClick={this.changeMonth.bind(this, 1)}><img alt="" src="/static/icons/desplegable-preguntas.png" /></div> }
				<div className="calendar" id="calendar"></div>
			</div>
		)
	}
}
