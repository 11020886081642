import React, { Component } from 'react';
import Footer from './Footer.jsx';
import FormInput from './FormInput.jsx';

import Forms from '../services/Forms.jsx';
import AsyncButton from './AsyncButton.jsx';
import $ from 'jquery';

class FormularioRestaurantes extends Component {
    constructor(props) {
        super(props);

		this.state = {
			errorMessage: ""
		};
	}
	
	componentDidMount() {
		$('body').addClass('bg-offwhite');
	}

	componentWillUnmount() {
		$('body').removeClass('bg-offwhite');
	}

    render() {
        return (
            <div className="formulario-contacto bg-offwhite">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>Spotnow atrae nuevos clientes a tu restaurante</h1>
                            <div className="subtitle">De forma dinámica para ellos y productiva para ti. Y garantizando cero —No Shows.</div>
							
							<h2>Datos</h2>
							<FormInput onChange={value => this.setState({ nombre: value })} required label="Nombre*" id="nombre" />
							<FormInput onChange={value => this.setState({ email: value })} required type="email" label="Email*" id="email"
								onValidate={value => {
									// eslint-disable-next-line no-control-regex
									if(!value.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/)) return 'Por favor introduce una dirección de email válida.';
								}}
							/>
							<FormInput onChange={value => this.setState({ nombre_restaurante: value })} required label="Nombre del restaurante*" id="nombre_restaurante" />
							<FormInput onChange={value => this.setState({ telefono: value })} required label="Teléfono*" id="telefono" />

							<FormInput onChange={value => this.setState({ web_restaurante: value })} required label="Web del restaurante*" id="web_restaurante" />
                        </div>
                    </div>

					<div className="error-message">{this.state.errorMessage}</div>

					<AsyncButton className="boton-enviar-contacto" onClick={button => {

						this.setState({ errorMessage: "" });

						if(
							!this.state.nombre ||
							!this.state.email ||
							!this.state.nombre_restaurante ||
							!this.state.telefono ||
							!this.state.web_restaurante
						) {
							button.reset();
							this.setState({ errorMessage: "Por favor rellena todos los campos obligatorios." });
							return;
						}

						Forms.sendForm('restaurantes', {
							nombre: this.state.nombre,
							email: this.state.email,
							nombre_restaurante: this.state.nombre_restaurante,
							telefono: this.state.telefono,
							web_restaurante: this.state.web_restaurante,
						}).then(() => {
							window.location.href = "/gracias-por-contactar-con-nosotros";
						});
					}} caption="Enviar" />
                </div>
                <Footer />
            </div>
        );
    }
}

export default FormularioRestaurantes;