import React from 'react';
import Session from '../services/Session';

export default class AuthGuard extends React.Component {
	constructor(props) {
		super(props);

		this.mounted = false;
		this.state = {
			authenticated: false
		};
	}

	componentDidMount() {
		this.mounted = true;
		Session.GetSession().then(session => {
			if(!session || !session.id) {
				sessionStorage.setItem('login_redirect', window.location.hash.replace('#', ''));
				window.location.href = '/Login';
			} else {
				if(!this.mounted) return;
				this.setState({ authenticated: true });
			}
		});
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	render() {
		if(this.state.authenticated) return null;

		return (
			<div className="auth-guard"></div>
		)
	}
}
