import React, { Component } from 'react';
import FormInput from './FormInput.jsx';
import Helper from './../services/Helper';
import Session from './../services/Session';
import $ from 'jquery';

import AsyncButton from './AsyncButton.jsx';

class CambiarContrasena extends Component {
    constructor(props) {
        super(props);
        this.state = {
            success_message: null,
            token: null
        };

        const m = window.location.search.match(/\?token=(.+)/i);
        if(m && m[1]) {
            this.state.token = m[1];
        }
    }

    render() {
        return (
            <div className="cambiar-contrasena">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 form-container">
                            { this.state.token && this.renderChange() }
                            { !this.state.token && this.renderRequest() }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderChange() {
        return (
            <React.Fragment>
                <h1>Cambiar contraseña</h1>
                { this.state.success_message && <div>{this.state.success_message}</div>}
                { !this.state.success_message && (
                    <React.Fragment>
                    <form autoComplete="off" style={{
                        display: 'grid',
                        gridTemplateColumns: '2fr 2fr',
                        gap: 10
                    }}
                    onSubmit={this.onSubmit.bind(this)}>
                        <input type="hidden" autoComplete="off" />
                        <FormInput required
                            type="password"
                            id="password"
                            label="Contraseña"
                            onValidate={value => {
                                if(value.length < 8) return 'La contraseña debe tener al menos 8 caracteres. Una contraseña fuerte debe contener letras, números y símbolos.';
                            }}
                            onChange={value => {
                                this.setState({ password: value })
                            }}
                        />
                        <FormInput required
                            type="password"
                            id="password2"
                            label="Repetir contraseña"
                            onValidate={value => {
                                if(value.length && this.state.password !== value) return 'Las contraseñas no coinciden.';
                            }}
                        />

                        { this.state.error_message && <div className="error-message">{this.state.error_message}</div> }

                        <AsyncButton style={{ gridColumn: 'span 2' }} ref={ref => this.buttonRef = ref} className="btn btn-brown" onClick={this.onSubmitChange.bind(this)} caption="Cambiar contraseña" />
                    </form>
                </React.Fragment>
                ) }
            </React.Fragment>
        );
    }

    renderRequest() {
        return (
            <React.Fragment>
                <h1>Cambiar contraseña</h1>
                { this.state.success_message && <div>{this.state.success_message}</div>}
                { !this.state.success_message && (
                    <React.Fragment>
                        <div>Introduce tu e-mail a continuación y enviaremos un enlace de verificación para que puedas cambiar tu contraseña:</div>
                            <input type="hidden" autoComplete="off" />
                            <FormInput
                                type="email"
                                id="email"
                                label="Email"
                                onValidate={value => {
                                    if(!Helper.ValidateEmail(value)) return 'Por favor introduce una dirección de email válida.';
                                }}
                                onChange={value => {
                                    this.setState({ login: value });
                                }}
                            />

                            {
                                this.state.error_message ? (
                                    <div className="error-message">{this.state.error_message}</div>
                                ) : null
                            }
                            <AsyncButton className="btn btn-brown" ref={ref => this.buttonRef = ref} onClick={this.onSubmit.bind(this)} caption="Enviar" />
                    </React.Fragment>
                ) }
            </React.Fragment>
        );
    }

    onSubmit(e) {
        if(e && e.preventDefault) e.preventDefault();
        if($('.form-input.not-validated').length) return false;
        
        Session.RequestPasswordChange(this.state.login).then(result => {
            if(result) {
                this.setState({
                    success_message: 'Hemos enviado un enlace de verificación a tu cuenta de correo electrónico. Recuerda comprobar también la carpeta de spam.'
                });
            } else {
                this.setState({
                    error_message: 'No se ha podido enviar tu enlace de recuperación. Por favor comprueba que tus datos sean correctos y vuelve a intentarlo.'
                });
            }
        });

        return false;
    }
    
    onSubmitChange(e) {
        if(e && e.preventDefault) e.preventDefault();
        if($('.form-input.not-validated').length) return;

        Session.PerformPasswordChange(this.state.password, this.state.token).then(result => {
            if(!result) {
                this.setState({
                    error_message: 'No se ha podido cambiar tu contraseña. Por favor comprueba que tus datos sean correctos y vuelve a intentarlo.'
                });
                return;
            }
            this.setState({
                success_message: 'Tu contraseña se ha cambiado con éxito. Ya puedes utilizarla para entrar.'
            });
        });
    }
}

export default CambiarContrasena;
