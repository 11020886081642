import React from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer.jsx';
import ImagenPaso from './ImagenPaso';
import $ from 'jquery';

import { Content } from './Content.jsx';

export default class PlanearEvento extends React.Component {
    componentDidMount() {
        $('header').addClass('bg-offwhite');
    }

    componentWillUnmount() {
        $('header').removeClass('bg-offwhite');
    }
    render() {
        return (
            <div className="planear-evento bg-offwhite">
                <div className="container full-width">
                    <div className="row">
                        <ImagenPaso className="col-md-6" imageUrl="/static/images/mockup/event-planning-welcome-img.png" />
                        <div className="col-md-6">
                            <div className="text-block">
                                <div className="allcaps eventos-supertitulo"><Content slug="eventos-supertitulo" /></div>
                                <h1><Content slug="eventos-titulo" /></h1>
                                <p><Content slug="eventos-subtitulo" /></p>
                                <Link to="/contratar-un-evento" className="btn btn-brown">Contratar un evento</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container full-width">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-band bg-offgrey">
                                <h2><Content slug="eventos-banda-titulo" /></h2>
                                <p><Content slug="eventos-banda-texto" /></p>
                                <div className="row logo-row pl-sm-3 pl-lg-2 justify-content-center">
                                    <Content slug="eventos-banda-html" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container eventos-destacado eventos-destacado-1 full-width">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="text-block">
                                <h2><Content slug="eventos-destacado-1-titulo" /></h2>
                                <p><Content slug="eventos-destacado-1-texto" /></p>
                                <Link to="/contratar-un-evento" className="btn btn-brown">Contratar un evento</Link>
                            </div>
                        </div>
                        <ImagenPaso className="col-md-6" imageUrl="/static/images/mockup/event-planning-business-img.png" />
                    </div>
                </div>
                <div className="container eventos-destacado eventos-destacado-2 full-width">
                    <div className="row">
                        <ImagenPaso className="col-md-6" imageUrl="/static/images/mockup/event-planning-pleasure-img.png" />
                        <div className="col-md-6">
                            <div className="text-block">
                                <h2><Content slug="eventos-destacado-2-titulo" /></h2>
                                <p><Content slug="eventos-destacado-2-texto" /></p>
                                <Link to="/contratar-un-evento" className="btn btn-brown">Contratar un evento</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container eventos-banda-inferior full-width">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-band bg-offgrey">
                                <h2><Content slug="eventos-banda-inferior-titulo" /></h2>
                                <div className="logos-clientes row pl-sm-3 pl-lg-2 justify-content-center pb-5">
                                    <Content slug="eventos-banda-inferior-html" />
                                </div>
                                <Link to="/contratar-un-evento" className="btn btn-brown">Contratar un evento</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}
