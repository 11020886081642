/* eslint-disable */
// File work in progress

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import AdminService from "../services/Admin";
import { APIAdminRequest } from "../services/API";
import Footer from "./Footer";

export default function Admin() {
    const [isAdmin, setIsAdmin] = useState(null);
    const [content, setContent] = useState();
    const [formData, setFormData] = useState({
        user: "",
        password: ""
    });

    function attemptLogin(e) {
        AdminService.Login(formData.user, formData.password).then(response => {
            window.location.reload();
        });
    }

    function attemptLogout(e) {
        AdminService.Logout().then(response => {
            window.location.reload();
        });
    }

    function handleInputChange(e) {
        let newFormData = {...formData};
        newFormData[e.target.id] = e.target.value;
        setFormData(newFormData);
    }

    function doSearch(searchQuery) {
        AdminService.GetContent(searchQuery).then(content => {
            setContent(content);
        });
    }

    useEffect(() => {
        AdminService.IsUserAdmin().then(isAdmin => {
            setIsAdmin(isAdmin);
        });
    }, []);

    if (isAdmin === null) {
        return null;
    }

    if (!isAdmin) {
        return (
            <div className="admin login-form">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <input onChange={handleInputChange} id="user" placeholder="Usuario" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <input onChange={handleInputChange} id="password" placeholder="Contraseña" type="password" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <button className="btn btn-brown" onClick={attemptLogin}>ENTRAR</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="admin">
            <div className="container">
                <div className="row menubar">
                    <input placeholder="Buscar contenido…" onKeyPress={e => {
                        if (e.key == "Enter") {
                            doSearch(e.target.value);
                        }
                    }} />
                    <button className="btn btn-small" onClick={attemptLogout}>Salir</button>
                </div>
            </div>
        </div>
    );
}