import React from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer.jsx';
import ImagenPaso from './ImagenPaso';
import $ from 'jquery';

import { Content } from './Content.jsx';

export default class ParaRestaurantes extends React.Component {
    componentDidMount() {
        $('header').addClass('bg-offwhite');
    }

    componentWillUnmount() {
        $('header').removeClass('bg-offwhite');
    }
    render() {
        return (
            <div className="para-restaurantes bg-offwhite">
                <div className="container full-width">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="text-block">
                                <h1><Content slug="para-restaurantes-titulo" /></h1>
                                <Link to="/contacto-restaurantes" className="btn btn-brown">UNIRSE A SPOTNOW</Link>
                            </div>
                        </div>
                        <ImagenPaso className="col-md-6" imageUrl="/static/images/mockup/chef-calling-standing.png" />
                    </div>
                </div>
                <div className="container full-width bg-offgrey nuestra-mision">
                    <div className="row nuestra-mision-inner">
                        <div className="col-md-6 mx-auto text-center">
                            <h2><Content slug="para-restaurantes-banda-titulo" /></h2>
                            <Content slug="para-restaurantes-banda-html" />
                        </div>
                    </div>
                </div>
                <div className="container full-width">
                    <Content slug="banda-clara-contenido" />
                </div>
                <div className="container full-width tus-clientes">
                    <div className="row">
                        <ImagenPaso className="col-md-6" slug="para-restaurantes-destacado-1-imagen" />
                        <div className="col-md-6 flex-col-align-start">
                            <h2><Content slug="para-restaurantes-destacado-1-titulo" /></h2>
                            <p><Content slug="para-restaurantes-destacado-1-subtitulo" /></p>
                            <Content slug="para-restaurantes-destacado-1-contenido" />
                            <Link to="/contacto-restaurantes" className="btn btn-brown">UNIRSE A SPOTNOW</Link>
                        </div>
                    </div>
                </div>
                <div className="container full-width tus-clientes">
                    <div className="row">
                        <ImagenPaso className="col-md-6" slug="para-restaurantes-destacado-2-imagen" />
                        <div className="col-md-6 flex-col-align-start">
                            <h2><Content slug="para-restaurantes-destacado-2-titulo" /></h2>
                            <p><Content slug="para-restaurantes-destacado-2-subtitulo" /></p>
                            <Content slug="para-restaurantes-destacado-2-contenido" />
                            <Link to="/contacto-restaurantes" className="btn btn-brown">UNIRSE A SPOTNOW</Link>
                        </div>
                    </div>
                </div>
                <div className="container full-width tus-clientes">
                    <div className="row">
                        <ImagenPaso className="col-md-6" slug="para-restaurantes-destacado-3-imagen" />
                        <div className="col-md-6 flex-col-align-start">
                            <h2><Content slug="para-restaurantes-destacado-3-titulo" /></h2>
                            <p><Content slug="para-restaurantes-destacado-3-subtitulo" /></p>
                            <Content slug="para-restaurantes-destacado-3-contenido" />
                            <Link to="/contacto-restaurantes" className="btn btn-brown">UNIRSE A SPOTNOW</Link>
                        </div>
                    </div>
                </div>
                <div className="container full-width bg-offgrey logos">
                    <div className="row">
                        <h2><Content slug="para-restaurantes-banda-inferior-titulo" /></h2>
                    </div>
                    <Content slug="para-restaurantes-banda-inferior-contenido" />
                </div>
                <Footer />
            </div>
        );
    }
}
