import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './js/components/App';
import './style.css';

render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
, document.getElementById('resaca'));