import { APIRequest, APIRequestCached } from './API';
import React from 'react';

export default class Events {
    static PeriodCutoffHour() {
        return 17;
    }

    static GetMealTimeForSlot(restaurant, slot) {
        if (!restaurant.uses_limited_slots) {
            return 0;
        }

        const startTime = slot.substr(0, 5);
        const startHour = startTime.substr(0, 2);

        if (startHour < Events.PeriodCutoffHour()) {
            return parseInt(restaurant.mean_lunch_time);
        } else {
            return parseInt(restaurant.mean_dining_time);
        }
    }

    static async search(search_parameters) {
        const response = await APIRequest('events/search', { parameters: JSON.stringify(search_parameters) });
        return response.data;
    }
    
    static async GetEventList() {
        const events = await APIRequestCached('events/top', null, 5000);
        return events;
    }

    static async get(id, slot = "") {
        const restaurant = await APIRequest('events/get', { id, slot });
        return restaurant.data;
    }

    static async getSimilar(id) {
        const similar = await APIRequest('events/similar', { id });
        return similar.data;
    }

    static async GetFeaturedTags() {
        const result = await APIRequest('events/featured-tags');
        return result.data;
    }

    static async getBySlug(id) {
        const restaurant = await APIRequest('events/get-by-slug', { id });
        return restaurant.data;
    }

    static async GetCities() {
        const cities = await APIRequest('events/get-cities');
        return cities.data;
    }

    static renderTagLinks(tags, onclick) {
        if(!tags) return '';
        return (
            <span className="tag-links">
                { JSON.parse(tags).map((t, k) => <a onClick={e => {
                    e.preventDefault();
                    onclick.call(e.currentTarget);
                }} key={k} href="#" data-tag={t}>{t.toUpperCase()}</a>) }
            </span>
        );
    }

    static RenderAvailabilityTag(pax) {
        if (pax == 0) {
            return <span>Completo</span>
        }

        return <span>{pax} {pax == 1 ? "plaza" : "plazas"}</span>;
    }

    static async GetAvailability(event, pax) {
        const availability = await APIRequest('events/availability', { event, pax });
        return availability.data;
    }

    static async GetAvailabilityMultiple(events, pax) {
        const availability = await APIRequest('events/availability-multiple', { events: events.join(","), pax: pax || 1 });
        return availability.data;
    }

    static async getAvailableSlots(restaurant, date, time, pax) {
        const availability = await APIRequest('events/time-availability', { restaurant, date, time, pax });
        return availability.data;
    }

    static async getByTag(tag, n) {
        const events = await APIRequest('events/by-tag', { tag, count: n });
        return events;
    }

    static async SetFavorite(restaurant_id, favorite = true) {
        await APIRequest('events/set-favorite', { restaurant_id, favorite: favorite ? 1 : 0 });
    }

    static async IsFavorite(restaurant_id) {
        const response = await APIRequest('events/is-favorite', { restaurant_id });
        return response.data;
    }

    static async GetFavorites() {
        const events = await APIRequest('events/get-favorites');
        return events.data;
    }

    static RenderButtonCaption(event) {
        return (<React.Fragment>RESERVAR AHORA</React.Fragment>);
        // if (parseInt(event.accepts_preorder)) {
        //     return (<React.Fragment>RESERVAR AHORA</React.Fragment>);
        // } else {
        //     return (<React.Fragment>Ticket {(event.price * 0.01).toLocaleString('es', {minimumFractionDigits: 2})} € | RESERVAR</React.Fragment>);
        // }
    }

    static async GetNearestAvailability(eventID) {
        const response = await APIRequest("events/get-nearest", { event_id: eventID });
        return response.data;
    }

    static async GetDates(eventID) {
        const response = await APIRequest("events/get-dates", { event_id: eventID });
        return response.data;
    }
}
