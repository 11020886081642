import { APIRequest } from "./API";

export default class Ciudades {
    static async GetCiudadesList() {
        const response = await APIRequest("restaurants/cities");

        let cities = [];

        response.data.forEach(row => {
            cities.push({
                active: true,
                title: row.city,
                image_file: Ciudades.GetCityImageName(row.city)
            });
        });

        const futureCiudades = await Ciudades.GetFutureCiudadesList();

        for(let futureCiudad of futureCiudades) {
            if(!cities.find(city => city["title"] === futureCiudad.title)) {
                cities.push(futureCiudad);
            }
        }

        return cities;
    }

    static async GetFutureCiudadesList() {
        let cities = [];
        const futureCitiesResponse = await APIRequest("restaurants/get-future-cities");

        futureCitiesResponse.data.forEach(row => {
            cities.push({
                active: false,
                title: row.name,
                image_file: Ciudades.GetCityImageName(row.name)
            });
        });

        return cities;
    }

    static async GetCiudadNameFromImageName(slug) {
        const ciudades = await Ciudades.GetCiudadesList();
        for(let ciudad of ciudades) {
            if(slug === Ciudades.GetCitySlug(ciudad["title"])) {
                return ciudad["title"];
            }
        }
        return slug;
    }

    static GetCityImageUrl(name) {
        return '/static/images/ciudades/' + Ciudades.GetCityImageName(name);
    }

    static GetCitySlug(name) {
        return name.toLowerCase().replace('à', 'a').replace('è', 'e').replace('ò', 'o').replace('á', 'a').replace('é', 'e').replace('í', 'i').replace('ó', 'o').replace('ú', 'u').replace('ñ', 'n').replace(' ', '_') ;
    }
    
    static GetCityImageName(name) {
        return Ciudades.GetCitySlug(name) + '.png';
    }
}