import React, { useEffect, useReducer, useRef } from 'react';
import {
	Switch,
	Route,
	useHistory
} from 'react-router-dom';

import $ from 'jquery';

import ScrollToTop from '../scroll-to-top';

import MainMenu from './MainMenu';
import MobileMenu from './MobileMenu';
import Home from './Home';
import FichaEvento from './FichaEvento';
import PlanearEvento from './PlanearEvento';
import ParaRestaurantes from './ParaRestaurantes';
import Eventos from './Eventos';
import Registro from './Registro';
import Login from './Login';
import FormularioEventos from './FormularioEventos';
import FormularioContacto from './FormularioContacto';
import FormularioRestaurantes from './FormularioRestaurantes';
import ContactoGracias from './ContactoGracias';
import RegistroGracias from './RegistroGracias';
import Pago from './Pago';
import ReservaCompletada from './ReservaCompletada';
import TerminosYCondiciones from './TerminosYCondiciones';
import PoliticaDePrivacidad from './PoliticaDePrivacidad';
import PreguntasFrecuentes from './PreguntasFrecuentes';
import PopupCookies from './PopupCookies';
import CambiarContrasena from './CambiarContrasena';
import RegistroActivar from './RegistroActivar';
import ReservaFallida from './ReservaFallida';
import MiCuenta from './MiCuenta';
import CambioEmail from './CambioEmail';
import Preorder from './Preorder';
import Admin from './Admin';

export default function App() {
	const [, forceUpdate] = useReducer(x => x + 1, 0);
	const history = useHistory();
	const resizeTimeout = useRef(null);
	const main_menu_ref = useRef(null);
	const history_unlisten = useRef(null);

	function onResize(e) {
		clearTimeout(resizeTimeout.current);
		resizeTimeout.current = setTimeout(() => {
			forceUpdate();
		}, 100);
	}

	useEffect(() => {
		$(window).on("resize", onResize);

		resizeTimeout.current = null;
		main_menu_ref.current = null;
		history_unlisten.current = history.listen((location, action) => {
			$('body.scroll-lock').removeClass('scroll-lock');
			if(main_menu_ref.current) {
				setTimeout(main_menu_ref.current.handleScroll, 500);
			}
		});

		return function cleanup() {
			$(window).off("resize", onResize);
			history_unlisten.current();
		}
	});

	return (
		<React.Fragment>
			<ScrollToTop />
			{window.innerWidth >= 768 && <header className="desktop"><MainMenu onRef={ref => main_menu_ref.current = ref} /></header>}
			{window.innerWidth < 768 && <header className="mobile"><MobileMenu /></header>}
			<section id="main">
				<Switch>
					<Route exact path="/">
						<Home />
					</Route>
					<Route path="/admin" exact>
						<Admin />
					</Route>
					<Route path="/eventos">
						<Eventos />
					</Route>
					<Route path="/evento/:event_id" component={FichaEvento} exact />
					<Route path="/evento/:event_id/extras" component={Preorder} exact />
					<Route path="/evento/:event_id/:availability_id" component={FichaEvento} exact />
					<Route path="/para-empresas">
						<PlanearEvento />
					</Route>
					<Route path="/registro">
						<Registro />
					</Route>
					<Route path="/login">
						<Login />
					</Route>
					<Route path="/contratar-un-evento">
						<FormularioEventos />
					</Route>
					<Route path="/contacto">
						<FormularioContacto />
					</Route>
					<Route path="/contacto-restaurantes">
						<FormularioRestaurantes />
					</Route>
					<Route path="/gracias-por-contactar-con-nosotros">
						<ContactoGracias />
					</Route>
					<Route path="/cuenta-creada">
						<RegistroGracias />
					</Route>
					<Route path="/activar-usuario">
						<RegistroActivar />
					</Route>
					<Route path="/pago">
						<Pago />
					</Route>
					<Route path="/reserva-completada">
						<ReservaCompletada />
					</Route>
					<Route path="/reserva-fallida">
						<ReservaFallida />
					</Route>
					<Route path="/terminos-y-condiciones">
						<TerminosYCondiciones />
					</Route>
					<Route path="/politica-de-privacidad">
						<PoliticaDePrivacidad />
					</Route>
					<Route path="/preguntas-frecuentes">
						<PreguntasFrecuentes />
					</Route>
					<Route path="/cambiar-contrasena">
						<CambiarContrasena />
					</Route>
					<Route path="/mi-cuenta">
						<MiCuenta />
					</Route>
					<Route path="/cambio-email">
						<CambioEmail />
					</Route>
				</Switch>
			</section>
			<PopupCookies />
		</React.Fragment>
	);
}
