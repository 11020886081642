import React, { Component } from 'react';
import BookingWidget from './BookingWidget.jsx';
import Modal from './Modal.jsx';
import ReactHtmlParser from 'react-html-parser';

import Carousel from "./Carousel";
import Icon from './Icon.jsx';
import Events from '../services/Events.jsx';
import Session from '../services/Session.jsx';
import Helper from '../services/Helper.jsx';
import LoadingIndicator from './LoadingIndicator.jsx';
import $ from 'jquery';

export default class FichaEvento extends Component {
    constructor(props) {
        super(props);

        let booking_data = Session.GetBookingData();
        this.booking_widget_ref = null;

        this.state = {
            isLoggedIn: false,
            similar: [],
            availability: [],
            currentAvailabilityIndex: 0,
            booking_data: booking_data || {
                pax: (booking_data && booking_data.pax) || 2,
                selected_date: '',
                selected_slot: ''
            }
        };
    }

    componentDidMount() {
        this.updateData();

        const openModalReferred = () => {
            if(!this.modal_ref) {
                setTimeout(openModalReferred.bind(this), 500);
            } else {
                // if(window.location.search.match(/modify-booking/)) {
                //     this.openModal();
                // }
            }
        };

        openModalReferred();
    }

    componentWillUnmount() {
        $('header').removeClass('simple');
    }

    async updateData(event_id) {
        $('header').addClass('simple');

        if(typeof event_id === 'undefined') {
            const id = this.props.match.params.event_id;

            if(id === "") {
                window.location.href = "/eventos";
            } else if(Number.isInteger(id)) {
                event_id = id;
            } else {
                const event = await Events.getBySlug(id);
                event_id = event.id;
            }
        }

        Promise.all([
            Events.GetDates(event_id),
            Events.getSimilar(event_id),
            Session.CheckIsLoggedIn()
        ]).then(async([availability, similar, isLoggedIn]) => {
            let currentAvailabilityIndex = 0;
            let currentSlot = "";

            if (this.props.match.params.availability_id) {
                availability.forEach((date, idx) => {
                    //Session.SetBookingData("selected_date", date.slot.split(" ")[0]);
                    if (date.availability_id == this.props.match.params.availability_id) {
                        currentAvailabilityIndex = idx;
                        currentSlot = date.slot;
                    }
                });
            }

            const event = await Events.get(event_id, currentSlot);
            event.event_id = event_id;

            this.setState({
                event,
                availability,
                similar,
                isLoggedIn,
                currentAvailabilityIndex
            });
        });
    }

    openModal() {
        return new Promise(async(resolve, reject) => {
            const event = JSON.stringify(this.state.event);

            if (Session.GetBookingData("selected_event") !== event) {
                Session.SetBookingData("preorder_selection", null);
            }

            Session.SetBookingData('selected_event', event);
            await this.booking_widget_ref.fetchPromos();
            this.modal_ref.open();
            setTimeout(() => {
                this.modal_booking_ref.forceUpdate();
            }, 500);
            resolve();
        });
    }

    renderOtherDates() {
        const dates = this.state.availability.filter(e => e.availability_id != this.state.event.id);
        return (
            <div>
                <a name="otras-fechas" style={{ position: "relative", top: -112 }}></a>
                <h3 className="related-events-h3">Otras fechas</h3>
                <Carousel
                    className="other-dates"
                    items={3}
                    elements={dates}
                    renderElement={(date, idx) => {
                    const bookingURL = "/evento/" + this.state.event.slug + "/" + date.availability_id;
                    return (
                        <div className="other-date" key={idx} style={{ position: 'relative' }}>
                            <div className="item-container">
                                <div className="carousel-image-container">
                                    <a className="image" href={bookingURL} style={{backgroundImage: 'url(/static/images/eventos/' + date.image_url + ')'}}>&nbsp;</a>
                                </div>
                                <div className="carousel-highlight-container">
                                    <div className="carousel-highlight" onClick={e => e.stopPropagation()}>{date.city}</div>
                                    <div className="carousel-highlight carousel-pax" onClick={e => e.stopPropagation()}>{Events.RenderAvailabilityTag(date.pax)}</div>
                                </div>
                                <a href={bookingURL}>
                                    <div className="carousel-title">{this.state.event.name}</div>
                                    <div className="carousel-date"><Icon name="calendar" pixelSize={16} />{Helper.FormatISODate(date.slot.split(" ")[0], 2)}</div>
                                    <div className="carousel-time"><Icon name="clock" pixelSize={16} />{date.slot.split(" ")[1].substr(0, 5)}</div>
                                    <div className="carousel-price"><Icon name="clock" pixelSize={16} />{Helper.FormatAmount((date.price) / 100.0)}/persona</div>
                                </a>
                            </div>
                        </div>
                    );
                }} />
            </div>
        );
    }

    render() {
        if(!this.state.event || !this.state.availability.length) return <LoadingIndicator/>;

        const dateString = Helper.FormatISODate(this.state.availability[this.state.currentAvailabilityIndex].slot.split(" ")[0]);
        const price = this.state.event.price_alt != -1 ? this.state.event.price_alt : this.state.event.price;
        const accepts_preorder = this.state.event.accepts_preorder_alt != -1 ? this.state.event.accepts_preorder_alt : this.state.event.accepts_preorder;
        const address_name = this.state.availability[this.state.currentAvailabilityIndex].address_name_alt ? this.state.availability[this.state.currentAvailabilityIndex].address_name_alt : this.state.availability[this.state.currentAvailabilityIndex].address_name;
        const address = this.state.availability[this.state.currentAvailabilityIndex].address_alt ? this.state.availability[this.state.currentAvailabilityIndex].address_alt : this.state.availability[this.state.currentAvailabilityIndex].address;

        return (
            <React.Fragment>
                <div className="container evento">
                    <div className="row">
                        <div className="event-cover" style={{ backgroundImage: "url(/static/images/eventos/" + this.state.availability[this.state.currentAvailabilityIndex].header_image_url + ")"}}></div>
                    </div>
                    <div className="row">
                        <div className="col-md-8 event-info">
                            <h1>{this.state.event.name}</h1>
                                <div className="event-address"><div className="address-name">{address_name}</div><div className="address">{address}</div></div>
                            <div className="tags">
                                {this.state.availability[this.state.currentAvailabilityIndex] && Events.RenderAvailabilityTag(this.state.availability[this.state.currentAvailabilityIndex].pax)}

                                {this.state.availability.length > 1 && <div className="more-dates-icon-container"><Icon onClick={e => {
                                    $("html, body").animate({
                                        scrollTop: $("a[name=otras-fechas]").offset().top
                                    });
                                }} name="otrasfechas" className="more-dates-icon" pixelSize={28} /><div className="icon-tooltip">Más fechas disponibles</div></div>}
                                </div>
                            <div className="event-description">
                                <div className="event-info-line"><Icon name="calendar" pixelSize={20} />{dateString}</div>
                                <div className="event-info-line"><Icon name="clock" pixelSize={20} />{this.state.availability[this.state.currentAvailabilityIndex].slot.split(" ")[1].substr(0, 5)}</div>
                                <div className="event-info-line"><Icon name="clock" pixelSize={20} />{Helper.FormatAmount(price / 100.0)}/persona</div>
                                <div className="event-text">{ReactHtmlParser(this.state.event.description)}</div>
                            </div>
                            {this.state.availability.length > 1 && this.renderOtherDates()}
                        </div>
                        <div className="col-md-4">
                            <BookingWidget disabled={this.state.availability[this.state.currentAvailabilityIndex].pax == 0} onConfirm={async confirmButton => {
                                Session.SetBookingData("selected_slot", this.state.availability[this.state.currentAvailabilityIndex].slot.split(" ")[1]);
                                Session.SetBookingData("selected_date", this.state.availability[this.state.currentAvailabilityIndex].slot.split(" ")[0]);
                                Session.SetBookingData("selected_full_date", this.state.availability[this.state.currentAvailabilityIndex].slot);
                                Session.SetBookingData("selected_event", JSON.stringify(this.state.event));
                                Session.SetBookingData("preorder_selection", null);

                                if (parseInt(accepts_preorder) === 1) {
                                    window.location.href = "/evento/" + this.state.event.slug + "/extras";
                                } else {
                                    sessionStorage.setItem('login_redirect', 'pago');
                                    window.location.href = '/login';
                                }
                            }} bookingData={this.state.booking_data} onRef={ref => this.booking_widget_ref = ref} className="margin-left" event={this.state.event} />
                        </div>
                    </div>
                    <div>
                        <h3 className="related-events-h3">Similares</h3>
                        <div className="related-events desktop-only">
                            {this.state.similar.slice(0, 3).map(event => (
                                <div key={event.id}>
                                    <a onClick={() => { window.location.href = '/evento/' + event.slug; this.updateData(event.id); }} className="event-image" style={{
                                        backgroundImage: 'url(/static/images/eventos/' + event.header_image_url + ')'
                                    }}>&nbsp;</a>
                                    <div className="event-name">{event.name}</div>
                                    <div className="event-city">{event.city}</div>
                                </div>
                            ))}
                        </div>

                        <div className="related-events mobile-only">
                            <div className="horizontal-scroll-container" style={{ width: this.state.similar.length * window.innerWidth - 60 * this.state.similar.length }}>
                                {this.state.similar.map(event => (
                                    <div key={event.id}>
                                        <a onClick={() => { window.location.href = '/evento/' + event.slug; this.updateData(event.id); }} className="event-image" style={{
                                            backgroundImage: 'url(/static/images/eventos/' + (event.image_url || event.event_image_url).image_url + ')'
                                        }}>&nbsp;</a>
                                        <div className="event-name">{event.name}</div>
                                        <div className="event-city">{event.city}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                heightMode="expand"
                overrideBodyScrolled={true}
                ref={ ref => this.modal_ref = ref }
                title={"OPCIONES " + this.state.event.name}
                renderContent={() => {
                    return (
                        <React.Fragment>
                            <BookingWidget
                            ref={ref => {
                                this.modal_booking_ref = ref;
                            }}
                            modalRef={this.modal_ref}
                            onConfirm={confirmButton => {
                                if(confirmButton) confirmButton.reset();
                                $('body').removeClass('scroll-lock');

                                if (Session.GetBookingData("preorder_path") === "preorder" && parseInt(this.state.event.accepts_preorder) === 1) {
                                    window.location.href = '/evento/' + this.state.event.slug + '/extras';
                                } else {
                                    Session.SetBookingData("preorder_selection", null);
                                    Session.RedirectAuthenticated("/pago");
                                }
                            }} layout="horizontal" bookingData={this.state.booking_data} event={this.state.event} />
                        </React.Fragment>
                    );
                }}
                onOpen={() => {
                    $(".container.evento").css("opacity", 0);
                }}
                onClose={() => {
                    $(".container.evento").css("opacity", "");
                    this.forceUpdate();
                }} />
            </React.Fragment>
        );
    }
}
