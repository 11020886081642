import React from "react";

async function sha256(message) {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(message);                    

    // hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // convert bytes to hex string                  
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
}

export default class TPVWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        // Producción:
        // https://pgw.ceca.es/tpvweb/tpv/compra.action

        // Pruebas
        // https://tpv.ceca.es/tpvweb/tpv/compra.action

        // Tarjetas de pruebas
        // 5540500001000004 CVV2 989 12/21
        // 5020470001370055 CVV2 989 12/21
        // 5020080001000006 CVV2 989 12/21
        // 4507670001000009 CVV2 989 12/21

        const pruebas = true;

        const merchantID = "";
        const acquirerBIN = "";
        const terminalID = "";
        const operacion = this.props.order + '-' + Date.now();
        const currency = "978";
        const cypherName = "SHA2";
        const URLOK = "https://spotnow.io/reserva-completada";
        const URLNOK = "https://spotnow.io/reserva-fallida";

        const hash = sha256((pruebas ? "" : "") + merchantID + acquirerBIN + terminalID + operacion + this.props.amount + currency + "2" + cypherName + URLOK + URLNOK);

        return (
            <form id="tpv-form" action={pruebas ? "https://tpv.ceca.es/tpvweb/tpv/compra.action" : "https://pgw.ceca.es/tpvweb/tpv/compra.action" } method="post">
                <input type="hidden" name="MerchantID" value={merchantID} />
                <input type="hidden" name="AcquirerBIN" value={acquirerBIN} />
                <input type="hidden" name="TerminalID" value={terminalID} />
                <input type="hidden" name="Num_operacion" value={operacion} />
                <input type="hidden" name="Importe" value={this.props.amount} />
                <input type="hidden" name="TipoMoneda" value={currency} />
                <input type="hidden" name="Exponente" value="2" />
                <input type="hidden" name="URL_OK" value={URLOK} />
                <input type="hidden" name="URL_NOK" value={URLNOK} />
                <input type="hidden" name="Cifrado" value={cypherName} />
                <input type="hidden" name="Pago_soportado" value="SSL" />
                <input type="hidden" name="Firma" value={hash} />
            </form>
        );
    }
}
